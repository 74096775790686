import { Box } from "@chakra-ui/react"
import { useGetUpgradeModal } from "../../../../../hooks"
import { useGetPagesImportLimitByPlan } from "../../../../../../shared/hooks/pricing"
import { ChecklistItem } from "."

export const UnlimitedPagesPlusImport = ({ forConfirmed }) => {
    const { planId } = useGetUpgradeModal()
    const pages_per_import = useGetPagesImportLimitByPlan({planId})
    return (
        <ChecklistItem forConfirmed={forConfirmed}>
            <Box display="inline">Add unlimited pages to your sitemaps, and import up to
                <Box display="inline" fontWeight={forConfirmed ? "unset" : "semibold"}> {pages_per_import?.toLocaleString()} pages</Box>
                <Box display="inline"> per sitemap</Box>
            </Box>
        </ChecklistItem>
    )
}
import { store } from '../../../../../store';
import { getURL } from '../../../../../store/actions/sitemap-actions';
import { compact } from 'lodash';

export { exportCSV } from './csv';
export { exportSitemap } from './xml';
export { exportSVG } from './svg';

export const getURLs = () => {
  const state = store.getState();
  const { sitemap } = state;
  const { root, section: subfolder } = sitemap?.data || {};
  // hierarchical data to flat data for force layout
  var urls = [];
  //
  function getURLFromNode(node) {
    // push url to array
    urls.push(getURL(node.id));
    // recurse
    node.children?.forEach(getURLFromNode);
    node._children?.forEach(getURLFromNode);
  }
  // from section or root
  getURLFromNode(subfolder ? subfolder : root);
  // sort URLS
  urls.sort();
  // website sections
  (subfolder ? subfolder : root).website_sections?.forEach(getURLFromNode)
  // return URLs
  return compact(urls);
};

import { getNewFirestoreDocId, sendHubspotCustomEvent, toTitleCase } from '../../helpers';
import { useEffect, useRef } from 'react';
import { useGetUpgradeModal, useGetUser } from "../../hooks";

import { Confirmed } from './Screens/Confirmed';
import { ConfirmedInviteTeam } from './Screens/ConfirmedInviteTeam';
import { Confirming } from './Screens/Confirming';
import { Default } from './Screens/Default';
import { Details } from './Screens/Details';
import { Formik } from 'formik';
import { Header } from './Components/Header';
import { InviteTeam } from './Screens/InviteTeam';
import { NewFile } from './Screens/NewFile';
import { PageColors } from './Screens/PageColors';
import { PageSections } from './Screens/PageSections';
import { PageSectionsColors } from './Screens/PageSectionsColors';
import { PagesPerImport } from './Screens/PagesPerImport';
import { PlansPricing } from './Screens/PlansPricing';
import { RevisionHistory } from './Screens/RevisionHistory';
import { RightConfirmed } from './Screens/Rights/Confirmed';
import { RightConfirmedInviteTeam } from './Screens/Rights/ConfirmedInviteTeam';
import { RightConfirming } from './Screens/Rights/Confirming';
import { RightDefault } from './Screens/Rights/Default';
import { RightNewFile } from './Screens/Rights/NewFile';
import { RightPageColors } from './Screens/Rights/PageColors';
import { RightPageSections } from './Screens/Rights/PageSections';
import { RightPageSectionsColors } from './Screens/Rights/PageSectionsColors';
import { RightPagesPerImport } from './Screens/Rights/PagesPerImport';
import { RightPeople } from './Screens/Rights/People';
import { RightRevisionHistory } from './Screens/Rights/RevisionHistory';
import { RightScreenshots } from './Screens/Rights/Screenshots';
import { Screenshots } from './Screens/Screenshots';
import { SplitModal } from '../Components/Modals/SplitModal';
import {
    Stack,
} from '@chakra-ui/react'
import { StartTrialUpgradeButton } from './Components/StartTrialUpgradeButton';
import { TrialSteps } from './Screens/Rights/TrialSteps';
import { getFirebase } from 'react-redux-firebase';
import { toggleUpgradeModal } from '../../store/actions/ui-actions';
import { useDispatch } from 'react-redux';

export const INITIAL_SCREENS = ['default', 'pages-limit', 'page-colors', 'revision-history', 'page-sections', 'page-sections-colors', 'new-file_', 'screenshots', 'invite-team', 'pages-per-import']

export const UpgradeModalContainer = () => {

    const dispatch = useDispatch()

    const { planId, stripeRedirectAttrs, screen } = useGetUpgradeModal()

    const isInitialScreen = INITIAL_SCREENS?.some(v => screen?.startsWith(v))

    const initialScreenRef = useRef(isInitialScreen ? screen : undefined)
    const currentScreenRef = useRef(screen)

    // update current screen ref (needed for unmount as state will be stale)
    useEffect(() => { currentScreenRef.current = screen }, [screen]);

    // abandoned post initial-screen without going to stripe
    useEffect(() => {
        return () => {
            let initialScreen = initialScreenRef?.current
            let currentScreen = currentScreenRef?.current
            if (initialScreen && initialScreen !== currentScreen) {
                sendHubspotCustomEvent('trial_abandoned', {
                    last_upgrade_modal_screen: currentScreen,
                    trial_sku: toTitleCase(planId),
                    upgrade_modal_type: initialScreen,
                    collection: initialScreen === 'new-file_user-flows' ? 'user-flows' : 'sitemaps'
                })
            }
        }
    }, [])

    const user = useGetUser()

    // ensure user is loaded
    if (!user?.id) return null;

    // continue
    const email = stripeRedirectAttrs?.email || user?.email
    const orgName = stripeRedirectAttrs?.orgName || ''
    const orgWebsite = stripeRedirectAttrs?.orgWebsite || ''
    const billingInterval = stripeRedirectAttrs?.billingInterval || 'month'

    return (
        <Formik
            validateOnMount
            initialValues={{ email, seats: 1, firstName: user?.firstName, lastName: user?.lastName, orgName, orgWebsite, billingInterval }}
            validate={(values) => {
                const errors = {};
                const attributes = Object.keys(values)
                attributes.forEach(attr => { // disabled validation for now
                    if (!values[attr]) errors[attr] = "You missed this one"
                })
                return errors;
            }}
            onSubmit={async (values) => {

                try {

                    const { billingInterval, seats, email, orgName, orgWebsite, firstName, lastName } = values
                    // pass user's orgId if adding plan to archived organization
                    const orgId = getNewFirestoreDocId("organizations", { newDocId: true })
                    const firebase = getFirebase()

                    const { origin } = window.location || {}

                    const sessionURL = await firebase.functions().httpsCallable('stripe-createCheckoutSession')({
                        referrerURL: `${origin}/app`,
                        planId,
                        email,
                        metadata: {
                            orgId,
                            orgName,
                            orgWebsite,
                            billingInterval,
                            seats,
                            user: user?.id,
                            firstName,
                            lastName,
                            // new attributes - used for Hubspot 'trial_started' event
                            upgrade_modal_type: initialScreenRef?.current,
                            collection: initialScreenRef?.current === 'new-file_user-flows' ? 'user-flows' : 'sitemaps'
                            // immediatelySub: false // no trial
                        }
                    });
                    if (sessionURL?.data) window.location = sessionURL.data;
                } catch (e) {
                    console.error(e)
                }
            }}
        >
            <SplitModal
                isCentered
                isOpen
                onClose={() => dispatch(toggleUpgradeModal({ showing: false }))}
                size="5xl"
                h="xl"
                left={(
                    <Stack w="md" align="start" p={9}>
                        <Header />
                        {[
                            /*** initial screens ***/
                            screen === 'default' && <Default key="default" />,
                            screen === 'page-colors' && <PageColors key="page-colors" />,
                            screen === 'revision-history' && <RevisionHistory key="revision-history" />,
                            screen === 'page-sections' && <PageSections key="page-sections" />,
                            screen === 'page-sections-colors' && <PageSectionsColors key="page-sections-colors" />,
                            screen?.startsWith('new-file') && <NewFile key="new-file" />,
                            screen === 'screenshots' && <Screenshots key="screenshots" />,
                            screen === 'invite-team' && <InviteTeam key="invite-team" />,
                            screen === 'pages-per-import' && <PagesPerImport key="pages-per-import" />,
                            /*** initial screens ***/
                            screen === 'plans-pricing' && <PlansPricing key="plans-pricing" />,
                            screen === 'details' && <Details key="details" />,
                            screen === 'confirming' && <Confirming key="confirming" />,
                            screen === 'confirmed' && <Confirmed key="confirmed" />,
                            screen === 'confirmed-invite-team' && <ConfirmedInviteTeam key="confirmed-invite-team" />, // this is for post-confirmation
                        ]}
                        {INITIAL_SCREENS?.some(v => screen?.startsWith(v)) && <StartTrialUpgradeButton />}
                    </Stack>
                )}
                right={(
                    [
                        screen === 'default' && <RightDefault key="default-right" />,
                        screen === 'page-colors' && <RightPageColors key="page-colors-right" />,
                        screen === 'revision-history' && <RightRevisionHistory key="revision-history-right" />,
                        screen === 'page-sections' && <RightPageSections key="page-sections-right" />,
                        screen === 'page-sections-colors' && <RightPageSectionsColors key="page-sections-colors-right" />,
                        screen?.startsWith('new-file') && <RightNewFile key={screen} />,
                        screen === 'screenshots' && <RightScreenshots key="screenshots-right" />,
                        screen === 'pages-per-import' && <RightPagesPerImport key="pages-per-import-right" />,
                        screen === 'invite-team' && <RightPeople key="people-right" />,
                        screen === 'confirming' && <RightConfirming key="confirming-right" />,
                        screen === 'confirmed' && <RightConfirmed key="confirmed-right" />,
                        screen === 'confirmed-invite-team' && <RightConfirmedInviteTeam key="confirmed-invite-team-right" />,
                        ['plans-pricing', 'details'].includes(screen) && <TrialSteps key="steps-right" planId={planId} />
                    ]
                )}
            />
        </Formik>
    )
}
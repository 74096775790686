import { getDefaultPalletteHeader, getInUserFlow, getIsDefaultPalletteHeader, getIsNavbarHidden, getTemplateDrawer } from '../../helpers'
import { initSitemapCoversCollections, initSitemapCoversDoc, initSitemapSectionsDoc } from './sitemap-actions'

import { chain } from '../../helpers/chain'
import { changeEditorPallette } from './editor-actions'
import { createRoot } from '../../screens/Sitemap/utils/app'
import { expandAll } from '../../screens/Sitemap/app/canvas/buttons'
import { init } from '../../screens/Sitemap/app/canvas/shared'
import { render as renderPages } from '../../screens/Sitemap/app/canvas/render'
import { render as renderUserFlow } from '../../screens/Sitemap/user-flows/render'
import { wait } from '../../../shared/helpers'

const render = () => {
  const inUserFlow = getInUserFlow()
  !inUserFlow ? renderPages() : renderUserFlow()
}

export const updateColorMode = (colorMode) => {
  return (dispatch, getState) => {
    dispatch({ type: 'UPDATE_COLOR_MODE', colorMode })
    const { sitemap, editor } = getState();
    // change sitemap pallette (if required)
    const isDefaultSitemapPalletteHeader = getIsDefaultPalletteHeader(sitemap?.pallette?.header)
    // change pallette for editor (if required)
    if (isDefaultSitemapPalletteHeader) {
      const isDefaultEditorPalletteHeader = getIsDefaultPalletteHeader(editor?.pallette?.header)
      if (isDefaultEditorPalletteHeader) dispatch(changeEditorPallette({ header: getDefaultPalletteHeader() }))
    }
    // re-render
    render()
  };
};

export const toggleSideMenu = () => {
  return (dispatch, getState) => {
    const state = getState();
    const { sideMenu } = state.ui;
    const collapsed = !sideMenu.collapsed;
    dispatch({
      type: 'TOGGLE_SIDEMENU',
      collapsed,
    });
  };
};

export const toggleDrawer = override => {
  return (dispatch, getState) => {
    const state = getState();
    const { sideDrawer } = state.ui;
    const collapsed = override ? true : !sideDrawer.collapsed;
    dispatch({
      type: 'TOGGLE_SIDEDRAWER',
      collapsed,
    });
  };
};

export const toggleSitemapsNavbar = override => {
  return (dispatch, getState) => {
    const isNavbarHidden = getIsNavbarHidden()
    const hidden = override === true ? true : !isNavbarHidden

    /*** hide rename inputs ***/
    // const renameInput = d3.select('#rename-page');
    // if (!renameInput.empty() && !renameInput.classed('hidden')) endRename();
    // const renamePageSectionInput = d3.select('#rename-page-section');
    // if (!renamePageSectionInput.empty() && !renamePageSectionInput.classed('hidden')) endRenamePageSection();
    /*** hide rename inputs ***/

    dispatch({ type: 'TOGGLE_SITEMAPS_NAVBAR', hidden });
  };
};

/*** ORGANIZATION SETTINGS MODAL ***/
export const showOrganizationSettingsModal = props => {
  return (dispatch, getState) => {
    const { organization } = getState();
    dispatch({
      type: 'SHOW_ORGANIZATION_SETTINGS_MODAL',
      id: organization.id,
      name: props.name,
      tab: props.tab ? props.tab : 'rename',
      window: props.window ? props.window : undefined
    });
  };
};
export const changeOrganizationSettingsModalTab = ({ tab, window }) => {
  return (dispatch, getState) => {
    dispatch({
      type: 'CHANGE_ORGANIZATION_SETTINGS_MODAL_TAB',
      tab,
      window
    });
  };
};
export const changeOrganizationSettingsModalWindow = (window) => {
  return (dispatch, getState) => {
    dispatch({
      type: 'CHANGE_ORGANIZATION_SETTINGS_MODAL_WINDOW',
      window
    });
  };
};
export const hideOrganizationSettingsModal = () => {
  return (dispatch, getState) => {
    dispatch({
      type: 'HIDE_ORGANIZATION_SETTINGS_MODAL',
    });
  };
};
export const clearAddUserError = () => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    dispatch({
      type: 'CLEAR_ADD_USER_ERROR',
    });
  };
};
/*** ORGANIZATION SETTINGS MODAL ***/

export const toggleUpgradeModal = ({ showing, planId, lockPlan, stripeRedirectAttrs, screen }) => {
  return (dispatch, getState) => {
    dispatch({
      type: 'TOGGLE_UPGRADE_MODAL',
      showing,
      planId: planId || 'pro',
      lockPlan,
      stripeRedirectAttrs,
      screen: showing ? (screen || 'default') : undefined
    });
  };
};

export const setUpgradeModalScreen = ({ screen }) => {
  return (dispatch, getState) => {
    const { lockPlan } = getState()?.ui?.UpgradeModal
    dispatch({
      type: 'SET_UPGRADE_MODAL_SCREEN',
      screen: screen || 'default',
      lockPlan
    });
  };
};

export const toggleNewSitemapModal = (opts) => {
  const { showing, createType, fromHistory, showDetailScreen, detailId, prefill } = opts || {}
  return (dispatch, getState) => {
    dispatch({
      type: 'TOGGLE_NEW_SITEMAP_MODAL',
      createType: createType || 'crawl',
      fromHistory,
      showDetailScreen,
      detailId,
      showing,
      prefill
    });
  };
};

export const toggleNewUserFlowModal = () => {
  return (dispatch, getState) => {
    const state = getState();
    const { NewUserFlowModal } = state.ui;
    const showing = !NewUserFlowModal.showing;
    dispatch({
      type: 'TOGGLE_NEW_USER_FLOW_MODAL',
      showing
    });
  };
};

export const toggleAddUsersModal = () => {
  return (dispatch, getState) => {
    const state = getState();
    const { AddUsersModal } = state?.ui || {};
    dispatch({
      type: 'TOGGLE_ADD_USERS_MODAL',
      showing: !AddUsersModal?.showing
    });
  }
}

export const toggleTemplateDrawer = ({ showing, template } = {}) => {
  return (dispatch, getState) => {

    dispatch({
      type: 'TOGGLE_TEMPLATE_DRAWER',
      meta: {
        showing,
        template,
        sitemap: template?.sitemap
      },
      async payload() {

        if (!showing) return;

        await wait(1000) // to allow docs to be present before creating root

        if (template?.sitemap) {

          const { pages, sections, covers } = template?.sitemap?.docs;

          if (pages) {

            // fail-safe to load wireframes
            const { collections } = await import('../../screens/Sitemap/app/covers/collections/exports') // load wireframes here
            dispatch(initSitemapCoversCollections(collections))
            
            const pagesForRoot = chain(pages)?.map((obj, key) => { return { ...obj, id: key } })?.value()
            const root = await createRoot(pagesForRoot);

            dispatch(initSitemapSectionsDoc({ pages: sections })) // set page_sections
            dispatch(initSitemapCoversDoc({ pages: covers }));

            return { root }

          }

        }
      },
    }).then(() => {
      expandAll()
      init()
    }).catch(e => {
      console.error(e);
    });
  }
}

export const toggleOnboardingModal = (opts) => {
  const { showing, key } = opts || {}
  return (dispatch, getState) => {
    dispatch({
      type: 'TOGGLE_ONBOARDING_MODAL',
      showing,
      key
    });
  };
};

import {
    Box,
    chakra,
    Text,
    useRadio,
    VStack,
    useColorModeValue as mode,
    WrapItem,
} from "@chakra-ui/react"
import * as React from 'react'
import { MdRadioButtonChecked, MdRadioButtonUnchecked } from 'react-icons/md'
import { Link } from 'react-router-dom'

const RadioBox = chakra('div', {
    baseStyle: {
        borderWidth: '3px',
        px: '6',
        pt: '12',
        pb: '8',
        rounded: 'md',
        cursor: 'pointer',
        transition: 'all 0.2s',
        _focus: {
            shadow: 'outline',
        },
    },
})

export const ButtonRadio = (props) => {
    const { index, label, icon, description, link, onClick } = props
    const { getInputProps, getLabelProps, state } = useRadio(props)
    const checkedStyles = { bg: "normal", borderColor: "normal" };
    const isHero = index === 0;
    const element = (
        <WrapItem>
            <label style={{ width: '100%' }} {...getLabelProps()}>
                <input {...getInputProps()} />
                <RadioBox
                    onClick={onClick}
                    lineHeight="normal"
                    maxW="218px"
                    borderWidth={`${isHero ? 6 : 3}px`}
                    style={{ borderImage: isHero ? "linear-gradient(90deg, rgba(0,167,225,1) 0%, rgba(46,217,195,1) 100%) 1" : "unset" }}
                    _checked={checkedStyles}
                    _hover={{ bg: "rarchy-bg-subtle" }}
                >
                    <VStack spacing="4">
                        <VStack textAlign="center">
                            <Box
                                aria-hidden
                                fontSize="6xl"
                                mb="3"
                                color="fg.muted"
                            >
                                {icon}
                            </Box>
                            <Text fontWeight="extrabold" fontSize="lg">
                                {label}
                            </Text>
                            <Text fontSize="sm" fontWeight="medium" color="fg.muted" px={2}>{description}</Text>
                        </VStack>
                    </VStack>
                </RadioBox>
            </label>
        </WrapItem>
    )
    if (link) return <Link to={link}>{element}</Link>
    return <Box>{element}</Box>;
}
import { createNodeId, getPageName, stratify, update } from '../app';
import { remove } from './remove';
import { store } from '../../../../store';
import { addPageChange, mergePagesEdits } from '../../../../store/actions/sitemap-actions';
import { hitPersonalSitemapPageLimit } from './upgrade';

// import amplitude from 'amplitude-js/amplitude';
import { toggleUpgradeModal } from '../../../../store/actions/ui-actions';
import { getSitemap } from '../../../../helpers';
import { select } from 'd3';


export const add = (selectedNode) => {

  const sitemap = getSitemap();

  const newNode = getNewNode(selectedNode);

  newNode.parent = selectedNode;
  newNode.depth = selectedNode.depth + 1;
  newNode.x0 = newNode.parent.x;
  newNode.y0 = newNode.parent.y;

  if (selectedNode.children) {
    selectedNode.children.splice(selectedNode.children.length + 1, 0, newNode);
  } else if (selectedNode._children) {
    // Push new node underneath the selected nodes collapsed children
    selectedNode._children.splice(selectedNode._children.length + 1, 0, newNode);
    selectedNode.children = selectedNode._children;
    selectedNode._children = null;
    // keep page open
    localStorage.setItem(`${sitemap?.id}#${selectedNode.id}`, true);
  } else {
    // create children if no children exist
    selectedNode.children = [];
    selectedNode.children.push(newNode);
    // keep page open
    localStorage.setItem(`${sitemap?.id}#${selectedNode.id}`, true);
  };
  // set indexes
  selectedNode.children.forEach((d, i) => { d.index = i; });
  // UPDATE HERE
  update();
  // save user change
  saveNewPageChange(newNode);
  // amplitude tracking
  // amplitude.getInstance().logEvent('SITEMAP_ADDED_NEW_PAGE', { id: sitemap?.id });
  // immediately rename (if not adding initial page for website section)
  // if (selectedNode.id) renamePage(newNode);
};

export const undoAddPage = undo => {
  /****
   * getting the most recent version of the node using it's id
   * this ensures we only overwrite the rename change, and not any merged changes from other users
   * ****/
  const node = select(`#node${undo.node.id}`);
  if (node.empty()) return;
  remove(node.datum(), true);
};

export const redoAddPage = redo => {
  /****
   * we can use the changed node here - there won't have been any edits from other users to the page as it's been removed
   * ****/
  add(redo.node.parent, redo.node);
};

export const addFromButton = (node, side) => {

  const sitemap = getSitemap();

  const newNode = getNewNode(node);

  if (sitemap?.format === 'tree') {
    if (side === 'above' || side === 'below') {
      //
      newNode.parent = node.parent;
      newNode.depth = node.depth;
      newNode.x0 = newNode.x;
      newNode.y0 = newNode.y;
      //
      const index = side === 'above' ? node.index : node.index + 1;
      node.parent.children.splice(index, 0, newNode);
      //
      node.parent.children.forEach((d, i) => d.index = i);
    }
    if (side === 'right') {
      //
      newNode.parent = node;
      newNode.depth = node.depth + 1;
      newNode.x0 = newNode.x;
      newNode.y0 = newNode.y;
      newNode.index = 0;
      //
      node.children = [newNode];
    }
  }

  if (sitemap?.format === 'tree-vertical') {
    if (side === 'left' || side === 'right') {
      //
      newNode.parent = node.parent;
      newNode.depth = node.depth;
      newNode.x0 = newNode.x;
      newNode.y0 = newNode.y;
      //
      const index = side === 'left' ? node.index : node.index + 1;
      node.parent.children.splice(index, 0, newNode);
    }
    //
    if (side === 'below') {
      //
      newNode.parent = node;
      newNode.depth = node.depth + 1;
      newNode.x0 = newNode.x;
      newNode.y0 = newNode.y;
      newNode.index = 0;
      //
      node.children = [newNode];
    }
    //
    if (node.parent && node.parent.children) {
      node.parent.children.forEach((d, i) => d.index = i);
    }
  }

  if (sitemap?.format === 'tree-vertical-matrix') {
    if (node.depth <= 1) {
      if (side === 'left' || side === 'right') {
        //
        newNode.parent = node.parent;
        newNode.depth = node.depth;
        newNode.x0 = newNode.x;
        newNode.y0 = newNode.y;
        //
        const index = side === 'left' ? node.index : node.index + 1;
        node.parent.children.splice(index, 0, newNode);
      }
      //
      if (side === 'below') {
        //
        newNode.parent = node;
        newNode.depth = node.depth + 1;
        newNode.x0 = newNode.x;
        newNode.y0 = newNode.y;
        newNode.index = 0;
        //
        if (!node.children) {
          node.children = [newNode]
        } else {
          node.children = [newNode, ...node.children];
          node.children.forEach((d, i) => { d.index = i; });
        }
      }
      //
      if (node.parent && node.parent.children) {
        node.parent.children.forEach((d, i) => d.index = i);
      }
    }
    if (node.depth > 1) {
      if (side === 'above' || (side === 'below' && !node.children)) {
        //
        newNode.parent = node.parent;
        newNode.depth = node.depth;
        newNode.x0 = newNode.x;
        newNode.y0 = newNode.y;
        //
        //
        const index = side === 'above' ? node.index : node.index + 1;
        node.parent.children.splice(index, 0, newNode);
        //
        node.parent.children.forEach((d, i) => d.index = i);
      }
      if ((side === 'below' && node.children) || side === 'right') {
        //
        newNode.parent = node;
        newNode.depth = node.depth + 1;
        newNode.x0 = newNode.x;
        newNode.y0 = newNode.y;
        newNode.index = 0;
        //
        node.children ? node.children.splice(0, 0, newNode) : (node.children = [newNode]);
      }
    }
  };

  if (sitemap?.format === 'indent') {
    if (side === 'above' || (side === 'below' && !node.children)) {
      //
      newNode.parent = node.parent;
      newNode.depth = node.depth;
      newNode.x0 = newNode.x;
      newNode.y0 = newNode.y;
      //
      //
      const index = side === 'above' ? node.index : node.index + 1;
      node.parent.children.splice(index, 0, newNode);
      //
      node.parent.children.forEach((d, i) => d.index = i);
    }
    if ((side === 'below' && node.children) || side === 'right') {
      //
      newNode.parent = node;
      newNode.depth = node.depth + 1;
      newNode.x0 = newNode.x;
      newNode.y0 = newNode.y;
      newNode.index = 0;
      //
      node.children ? node.children.splice(0, 0, newNode) : (node.children = [newNode]);
    }
  }
  // ensure parent stays open when adding new page
  localStorage.setItem(`${sitemap?.id}#${newNode.parent.id}`, true);
  // UPDATE HERE
  update();
  // save page change
  saveNewPageChange(newNode);
  // immediately rename - BREAKS SAVING FOR UNDO?? (doesn't seem to anymore)
  // renamePage(newNode);
};

export const saveNewPageChange = (newNode) => {
  /*** set indexes for existing children of same parent ***/
  const childrenWithNewIndexes = [];
  const childrenWithOldIndexes = [];
  if (Array.isArray(newNode.parent.children)) {
    newNode.parent.children.forEach((d, i) => { if (d.id !== newNode.id) childrenWithNewIndexes.push({ id: d.id, index: i }) });
    newNode.parent.children.filter(d => d.id !== newNode.id).forEach((d, i) => { childrenWithOldIndexes.push({ id: d.id, index: i }) });
  }
  /*** set indexes for existing children of same parent ***/
  const newPageData = {
    name: newNode.name,
    index: newNode.index,
    parent: newNode.parent.id,
    website_section: newNode.website_section
  };
  var obj = [
    {
      action: 'add',
      id: newNode.id,
      ...newPageData
    },
    ...childrenWithNewIndexes
  ];
  const change = {
    id: new Date().getTime(),
    data: obj,
  };
  const history = {
    action: 'add',
    node: newNode.id,
    data: obj,
    childrenWithOldIndexes
  };

  /*** merge page edits ***/
  const sitemap = getSitemap();
  const pagesObj = { [newNode.id]: newPageData };
  childrenWithNewIndexes.forEach(c => pagesObj[c.id] = { ...sitemap?.docs.pages[c.id], index: c.index });
  store.dispatch(mergePagesEdits({ pages: pagesObj }))
  /*** merge page edits ***/

  setTimeout(() => {
    store.dispatch(addPageChange({ change, history }));
  }, 500);

}

const getNewNode = (selectedNode) => {

  /*** ensure user in free sitemap can not go over 50 pages ***/
  const hasHitPageCount = hitPersonalSitemapPageLimit(1);
  if (hasHitPageCount) {
    store.dispatch(toggleUpgradeModal({ showing: true, planId: 'pro', screen: 'pages-limit' }));
    return null;
  }
  /*** ensure user in free sitemap can not go over 50 pages ***/

  var newNode = stratify([
    {
      name: 'Page',
      parent: null,
    },
  ]);

  newNode.url = newNode.data.url;
  newNode.name = getPageName(newNode.data.name);
  if (selectedNode.website_section) newNode.website_section = selectedNode.website_section;
  newNode.id = createNodeId();
  delete newNode.data;

  return newNode;

}
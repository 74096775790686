import { Button, DarkMode } from "@chakra-ui/react"
import { useHasLoadedFiles, useInOrganization, useInProPlan, useIsCreatorOfOrganization, useIsLoggedInAndGotUser } from "../../../hooks"

import { MdTipsAndUpdates } from "react-icons/md"
import { toggleUpgradeModal } from "../../../store/actions/ui-actions"
import { useCanStartNewTrial } from "../../../../shared/hooks/pricing"
import { useDispatch } from "react-redux"

export const Upgrade = () => {
    const dispatch = useDispatch()
    const isLoggedIn = useIsLoggedInAndGotUser()
    const canStartNewTrial = useCanStartNewTrial()
    const inOrganization = useInOrganization()
    const inProPlan = useInProPlan()
    const hasLoadedFiles = useHasLoadedFiles()
    const isCreatorOfOrg = useIsCreatorOfOrganization({ onlyActive: true }) // false for testing
    if (!hasLoadedFiles) return null
    if (isCreatorOfOrg) return null
    if (inOrganization && !inProPlan) return null // don't show if user is in enterprise plan or above
    if (!isLoggedIn) return null
    return (
        <DarkMode>
            <Button
                w="full"
                fontSize="sm"
                leftIcon={<MdTipsAndUpdates fontSize="16px" color="#ECC94B" />}
                iconSpacing={3}
                color="white"
                bgColor="whiteAlpha.200"
                _hover={{ bgColor: "whiteAlpha.300" }}
                onClick={() => {
                    dispatch(toggleUpgradeModal({ showing: true, planId: inProPlan ? 'enterprise' : 'pro', lockPlan: inProPlan }))
                }}>
                {inProPlan ? `Upgrade to Enterprise` : (canStartNewTrial ? 'Try Pro for free' : 'Upgrade to Pro')}
            </Button>
        </DarkMode>
    )
}
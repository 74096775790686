import { Box, ListItem, UnorderedList, VStack } from "@chakra-ui/react"
import { useGetUpgradeModal } from "../../../hooks"
import { TRIAL_DAYS, useCanStartNewTrial, useGetPagesImportLimitByPlan } from "../../../../shared/hooks/pricing"
import { WhatYoullGet } from "../Components/Messaging/WhatYoullGet"
import { CollaborateWithOthersListItem, FolderPermissionsListItem } from "../Components/Messaging/ListItems"

export const InviteTeam = () => {
    const { planId } = useGetUpgradeModal()
    const canStartNewTrial = useCanStartNewTrial()
    const pages_per_import = useGetPagesImportLimitByPlan({ planId })
    return (
        <VStack key="default" fontSize="sm" align="start" mt={3} spacing={5}>
            <Box>
                <strong>
                    {`Invite 4+ people to join your Rarchy team`}
                </strong>. {canStartNewTrial ? `Try Rarchy Enterprise for free for ${TRIAL_DAYS} days.` : 'Upgrade to Rarchy Enterprise today.'}
            </Box>
            <WhatYoullGet />
            <UnorderedList spacing={4} mt={-1.5}>
                <CollaborateWithOthersListItem />
                <FolderPermissionsListItem />
                <ListItem>
                    {`Work on bigger projects: all users in your team can import up to ${pages_per_import?.toLocaleString()} pages per sitemap`}
                </ListItem>
            </UnorderedList>
        </VStack>
    )
}
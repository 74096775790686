import { CheckIcon, ChevronDownIcon } from "@chakra-ui/icons"
import { Button, Menu, MenuButton, MenuItem, MenuList, Text } from "@chakra-ui/react"
import { useDispatch } from "react-redux"
import { toggleUpgradeModal } from "../../../../store/actions/ui-actions"
import { useGetUpgradeModal, useInOrganization } from "../../../../hooks"
import { useIsTrialingOrCanStartNewTrial } from "../../../../../shared/hooks/pricing"

export const HeaderWithMenu = () => {
    const dispatch = useDispatch()
    const isTrialingOrCanStartNewTrial = useIsTrialingOrCanStartNewTrial()
    const { planId, screen, lockPlan } = useGetUpgradeModal()
    return (
        <>
            <Text display="inline" fontSize="lg" fontWeight="semibold">{!isTrialingOrCanStartNewTrial ? "Upgrade to" : "Try"} </Text>
            <Menu>
                <MenuButton
                    as={Button}
                    py={5}
                    pr={2}
                    textTransform="capitalize"
                    variant="outline"
                    fontSize="lg"
                    size="sm"
                    rightIcon={lockPlan ? null : <ChevronDownIcon mt={0.5} />}
                    _hover={{ cursor: lockPlan ? "initial" : "pointer", bgColor: lockPlan ? "none" : "normal" }}
                    _active={{ cursor: lockPlan ? "initial" : "pointer", bgColor: lockPlan ? "none" : "normal" }}
                >
                    {`Rarchy ${planId}`}
                </MenuButton>
                {!lockPlan && (
                    <MenuList>
                        {['pro', 'enterprise'].map(newPlanId => {
                            const isPlan = newPlanId === planId
                            return (
                                <MenuItem
                                    key={newPlanId}
                                    fontSize="md"
                                    fontWeight="medium"
                                    textTransform="capitalize"
                                    onClick={() => {
                                        dispatch(toggleUpgradeModal({ showing: true, planId: newPlanId, screen }))
                                    }}
                                >
                                    {isPlan && (<CheckIcon mr={3.5} color="fg.muted" fontSize="sm" />)}
                                    <Text pl={!isPlan ? 7 : 0}>{`Rarchy ${newPlanId}`}</Text>
                                </MenuItem>
                            )
                        })}
                    </MenuList>
                )}
            </Menu>
            {isTrialingOrCanStartNewTrial && <Text display="inline" fontSize="lg" fontWeight="semibold"> for free</Text>}
        </>
    )
}
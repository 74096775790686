import { Box, Flex, Stack, Tooltip } from "@chakra-ui/react";
import { PERMISSIONS, ValidateInvited, chakraStyles, getPermissionsKey } from "../helpers";
import { SendInvite, handleSendInvite } from "./SendInvite";
import { isEmpty, trim, uniq, uniqBy } from 'lodash';
import { useCanEditFile, useGetOrganization, useIsOrganizationAdmin } from "../../../../../hooks";
import { useContext, useRef, useState } from "react";

import { AddUsersContext } from "../../../../Users/AddUsersHOC";
import { CreatableSelect } from "chakra-react-select";
import { Formik } from "formik";
import { Permissions } from "../Permissions";
import { ShareFileContext } from "../../../../Files/Components/Share/ShareFileHOC";
import { useGetBillingInterval } from "../../../../../../shared/hooks/pricing";
import { validate } from 'email-validator'

export const Invite = ({ forOrganization }) => {

    const organization = useGetOrganization()

    const forFileShare = !forOrganization

    const { file, inputValue, setInputValue, setPeopleToInvite, peopleToInvite } = useContext(forOrganization ? AddUsersContext : ShareFileContext)

    const permissionsKey = getPermissionsKey(forOrganization)

    const hasPeopleToInvite = !isEmpty(peopleToInvite)

    const inputElement = useRef(null)
    const [menuIsOpen, setMenuIsOpen] = useState(false)

    const billingInterval = useGetBillingInterval()

    const handleSetValue = (email) => {
        const isValidEmail = validate(email);
        if (isValidEmail) {
            setPeopleToInvite((prev) => uniqBy([...prev, { label: email, email }], 'email'));
            setInputValue('');
        }
    }

    ValidateInvited({ forOrganization, organization, file, peopleToInvite, setPeopleToInvite })

    /*** can invite ***/
    const isAdmin = useIsOrganizationAdmin()
    const canEditFile = useCanEditFile(file)
    if (forFileShare && !canEditFile) return null // don't share invite input if can't edit shared file
    /*** can invite ***/
    
    const isDisabled = (forOrganization && !isAdmin)
    
    return (
        <Box px={7}>
            <Formik
                initialValues={{ seats: 1, billingInterval, permission: PERMISSIONS?.[permissionsKey]?.[0], message: '' }}
                onSubmit={async (values, actions) => {
                    try {
                        await handleSendInvite({ values, forOrganization, file, setInputValue, peopleToInvite, setPeopleToInvite })
                    } catch (e) {
                        console.error(e)
                    }
                }}
            >
                {({
                    values,
                    setFieldValue,
                    handleSubmit,
                }) => (
                    <form onSubmit={handleSubmit}>
                        <Stack spacing={5}>
                            <Tooltip isDisabled={!isDisabled} label="Only account admins can invite people" variant="rounded-sm" openDelay={500} hasArrow>
                                <Stack w="full" direction="row" justify="space-between">
                                    <CreatableSelect
                                        ref={inputElement}
                                        components={{ DropdownIndicator: null }}
                                        isMulti
                                        options={[]}
                                        isDisabled={isDisabled}
                                        menuIsOpen={menuIsOpen}
                                        onMenuOpen={() => setMenuIsOpen(true)}
                                        onMenuClose={() => setMenuIsOpen(false)}
                                        formatCreateLabel={userInput => `Add ${userInput}...`}
                                        noOptionsMessage={() => "Enter email addresses..."}
                                        chakraStyles={chakraStyles(values?.permission)}
                                        isClearable={false}
                                        placeholder="Enter or paste email addresses..." // {forOrganization ? "Enter or paste email addresses..." : "Add as many people as you need"}
                                        getOptionLabel={(option) => { return option.error ? option.renderError : option.label }}
                                        getOptionValue={(option) => option.email}
                                        onChange={(newValue) => { setPeopleToInvite(newValue) }}
                                        onCreateOption={(newValue) => {
                                            handleSetValue(trim(newValue))
                                        }}
                                        onInputChange={(newValue) => {
                                            newValue = trim(newValue);
                                            const emails = newValue?.split(/\s*,\s*|\s+/);
                                            if (emails.length > 1) {
                                                uniq(emails).forEach(inputValue => handleSetValue(inputValue))
                                                setMenuIsOpen(false)
                                            } else {
                                                setInputValue(newValue)
                                            }
                                        }}
                                        onKeyDown={(e) => {
                                            if (!inputValue) return;
                                            switch (e.key) {
                                                case 'Enter':
                                                case 'Tab':
                                                case ' ':
                                                case ',':
                                                    handleSetValue(trim(inputValue))
                                                    setMenuIsOpen(false);
                                                    e.preventDefault();
                                            }
                                        }}
                                        inputValue={inputValue}
                                        value={peopleToInvite}
                                    />
                                    {hasPeopleToInvite && (
                                        <Flex>
                                            <Permissions
                                                newPermission={values?.permission}
                                                opts={{ variant: "outline", size: "lg" }}
                                                forOrganization={forOrganization}
                                                onChange={(permission => { setFieldValue("permission", permission) })}
                                            />
                                        </Flex>
                                    )}
                                </Stack>
                            </Tooltip>
                        </Stack>
                        {hasPeopleToInvite && <SendInvite forOrganization={forOrganization} />}
                    </form>
                )}
            </Formik>
        </Box>
    )
}
import { Box, Button, Center, Icon, Stack } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { toggleUpgradeModal } from "../../../store/actions/ui-actions";
import { useGetItemNameFromDoc, useHasFreeUserCreatedFile, useInOrganization } from "../../../hooks";
import { MdTipsAndUpdates } from "react-icons/md";
import { useCanStartNewTrial } from "../../../../shared/hooks/pricing";

export const UpgradeToCreateFile = ({ collection }) => {
    const dispatch = useDispatch()
    const hasFreeUserCreatedFile = useHasFreeUserCreatedFile(collection)
    const itemType = useGetItemNameFromDoc({ collection })?.toLowerCase()
    const inOrganization = useInOrganization()
    const canStartNewTrial = useCanStartNewTrial()
    if (inOrganization) return null
    if (!hasFreeUserCreatedFile) return null;
    return (
        <Box
            mt={3}
            w="255px"
            h="216px"
            rounded="md"
            p={2}
            bg="rarchy-color-file"
            // bgImage="linear-gradient(90deg, rgba(0,167,225,1) 0%, rgba(46,217,195,1) 100%) 1"
            borderColor="rarchy-border"
            _hover={{ cursor: 'pointer' }}
            onClick={() => {
                dispatch(toggleUpgradeModal({ showing: true, screen: `new-file_${collection}`, planId: 'pro' }))
            }}
        >
            <Center
                rounded="md"
                h="full"
                bgColor="rarchy-bg-sitemap-editor"
            >
                <Stack w="full" align="center" spacing={4} justify="space-between">
                    <Icon as={MdTipsAndUpdates} fontSize="5xl" color="#ECC94B" />
                    <Stack w="full" px={2.5} fontSize="sm" fontWeight="medium" color="fg.muted" textAlign="center" spacing={3}>
                        <Box>{`Need to create another ${itemType}?`}</Box>
                        <Button variant="link" color="rarchy-link" size="sm">{canStartNewTrial ? "Try Rarchy Pro for free" : "Upgrade to Rarchy Pro"}</Button>
                    </Stack>
                </Stack>
            </Center>
        </Box>
    )
}
import {
    Box,
    useDisclosure
} from "@chakra-ui/react"
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useGetItems, useGetSitemap, useGetUserFlow, useInEditor, useInSitemap, useInUserFlow, usePrevious } from '../../../../hooks';

import { PagesDrawer } from './Pages';
import { SearchButton } from './SearchButton'
import { SearchModal } from './SearchModal';
import { isEmpty } from 'lodash';
import { useHotkeys } from 'react-hotkeys-hook'
import { useSelector } from 'react-redux';

const minW = "lg";

export const Search = () => {

    const inEditor = useInEditor()

    const { isOpen, onOpen, onToggle, onClose } = useDisclosure()
    const { isOpen: isDrawerOpen, onOpen: onDrawerOpen, onClose: onDrawerClose } = useDisclosure()
    const searchButton = useRef()
    const [BCR, setBCR] = useState({})
    const [numberOfItems, setNumberOfItems] = useState(0)

    const sitemap = useGetSitemap()
    const { section: subfolder } = sitemap?.data || []

    useEffect(() => {
        if (searchButton.current && isEmpty(BCR)) setBCR(searchButton.current.getBoundingClientRect())
    }, [])

    UpdateNumberOfPages({ inEditor, subfolder, numberOfItems, setNumberOfItems })

    useLayoutEffect(() => {
        /*** window resize ***/
        function handleResize() {
            const rect = searchButton.current.getBoundingClientRect()
            if (rect.x !== 0 && rect.x !== BCR.x) setBCR(rect)
        }
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
        /*** window resize ***/
    })

    useHotkeys('ctrl+k, command+k', e => { onToggle(); e.preventDefault() })
    return (
        <>
            <SearchButton onOpen={onOpen} isOpen={isOpen} onClose={onClose} buttonRef={searchButton} numberOfItems={numberOfItems} minW={minW} />
            {isOpen && (
                <>
                    <Box bg="blackAlpha.300" zIndex={1} position="fixed" top={0} left={0} w="full" h="full" opacity={0.5} onClick={onClose} />
                    <SearchModal BCR={BCR} isOpen={isOpen} onClose={onClose} numberOfItems={numberOfItems} onDrawerOpen={onDrawerOpen} minW={minW} />
                </>
            )}
            {inEditor && <PagesDrawer isDrawerOpen={isDrawerOpen} onDrawerClose={onDrawerClose} />}
        </>
    )
}

const UpdateNumberOfPages = ({ subfolder, numberOfItems, setNumberOfItems }) => {

    const foldersAndFiles = useGetItems()

    const inEditor = useInEditor()

    const inSitemap = useInSitemap()
    const sitemap = useGetSitemap()
    const prevSitemap = usePrevious(sitemap)

    const inUserFlow = useInUserFlow()
    const flow = useGetUserFlow()

    useEffect(() => {
        // folders and files
        if (!inEditor) {
            if (foldersAndFiles?.length !== numberOfItems) setNumberOfItems(foldersAndFiles?.length)
        }
        // in editor
        if (inEditor) {
            if (inSitemap && prevSitemap) {
                if (!subfolder) {
                    const { pages } = sitemap?.docs || {}
                    if (numberOfItems !== Object.keys(pages)?.length !== Object.keys(prevSitemap?.docs.pages).length) {
                        const no = Object.keys(pages).length;
                        setNumberOfItems(no);
                    }
                } else {
                    const no = countPagesInSubfolder(subfolder);
                    setNumberOfItems(no);
                }
            }
            if (inUserFlow) {
                if (numberOfItems !== flow?.data?.nodes?.length) {
                    const no = flow?.data?.nodes?.length;
                    setNumberOfItems(no);
                }
            }
        }
    }, [foldersAndFiles, inEditor, subfolder, prevSitemap]);
}

const countPagesInSubfolder = (root) => {
    let count = 0;
    function recurse(node) {
        count++
        var children = node._children ? node._children : node.children;
        children?.forEach(n => recurse(n));
    }
    recurse(root);
    return count;
}
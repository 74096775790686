import { Box, Button, Stack, Link as ChakraLink } from "@chakra-ui/react"
import { ArrowForwardIcon } from "@chakra-ui/icons"
import { setUpgradeModalScreen, toggleUpgradeModal } from "../../../store/actions/ui-actions"
import { useGetUpgradeModal, useInOrganization } from "../../../hooks"
import { UpgradeToEnterpriseInStripe } from "./UpgradeToEnterprise"
import { useDispatch } from "react-redux"
import { useCanStartNewTrial, useIsTrialing } from "../../../../shared/hooks/pricing"
import { Link } from "react-router-dom"
import { toTitleCase } from "../../../helpers"

export const StartTrialUpgradeButton = ({ hideCancelMessaging }) => {

    const dispatch = useDispatch()

    const { planId = 'pro,', lockPlan } = useGetUpgradeModal()

    const inOrganization = useInOrganization()

    const canStartNewTrial = useCanStartNewTrial()

    return (
        <Stack mt={3} spacing={5} w="full" align="center">
            {canStartNewTrial && !hideCancelMessaging && (
                <Box fontSize="sm">
                    <Box fontWeight="semibold" display="inline">Cancel anytime. </Box>
                    <Box display="inline">We’ll remind you via email 7 days before your trial ends.</Box>
                </Box>
            )}
            <Stack w="full" align="center" spacing={3}>
                <Button
                    w="full"
                    colorScheme="blue"
                    onClick={() => {
                        dispatch(toggleUpgradeModal({ showing: true, planId, lockPlan, screen: 'plans-pricing' }))
                    }}
                >
                    {canStartNewTrial ? `Try Rarchy ${toTitleCase(planId)} for free` : `Upgrade to Rarchy ${toTitleCase(planId)}`}
                </Button>
                <Box fontSize="13px" color="fg.muted">
                    <ChakraLink
                        as={Link}
                        to="/app/pricing"
                        textDecor="underline"
                        onClick={() => dispatch(toggleUpgradeModal({ showing: false }))}>
                        {`Learn more`}
                    </ChakraLink>
                    {` about Rarchy’s premium plans`}
                </Box>
            </Stack>
        </Stack>
    )
}
import { CalendarIcon } from '@chakra-ui/icons';
import {
    Box,
    Img,
    Link,
    Progress,
    Stack,
    Step,
    StepDescription,
    StepIndicator,
    StepTitle,
    Stepper,
    useSteps,
    useColorModeValue as mode
} from '@chakra-ui/react'
import { FaRegBell } from 'react-icons/fa';
import { MdTipsAndUpdates } from 'react-icons/md';

import dayjs from "dayjs";
import { toTitleCase } from '../../../../helpers';
import { useState } from 'react';
import { useGetNumberOfDaysLeftInTrial, useIsTrialing, useIsTrialingOrCanStartNewTrial } from '../../../../../shared/hooks/pricing';

const LightImage = "https://help.rarchy.com/hubfs/%2B%205%2c000-min-1080.png"
const DarkImage = "https://help.rarchy.com/hubfs/%2B%205%2c000%20-%20Dark-min-1080.png"

export const TrialSteps = ({ planId }) => {

    const planName = toTitleCase(planId);

    const reviewsByPlan = reviews.filter(f => f.plan === planId)
    const [reviewIndex] = useState(Math.floor(Math.random() * reviewsByPlan.length))
    const reviewMessage = reviewsByPlan?.[reviewIndex]?.message

    const isTrialing = useIsTrialing()
    const daysLeftInTrial = useGetNumberOfDaysLeftInTrial()

    const steps = [
        { title: `Today - ${isTrialing ? `Upgrade to Rarchy Enterprise` : `Free trial for ${daysLeftInTrial} days`}. Cancel anytime.`, description: `${isTrialing ? 'Upgrade your free trial' : `Start your free Rarchy ${planName} trial`} today and enjoy premium features with higher limits.` },
        { title: dayjs(new Date()).add(daysLeftInTrial - 7, 'day').format('ll'), description: `We'll send you a reminder 7 days before your Rarchy ${planName} trial ends.` },
        { title: dayjs(new Date()).add(daysLeftInTrial, 'day').format('ll'), description: "Your subscription starts, unless you've cancelled during the trial." },
    ];

    const { activeStep } = useSteps({ index: 0, count: steps.length })

    const isTrialingOrCanStartNewTrial = useIsTrialingOrCanStartNewTrial()

    if (!isTrialingOrCanStartNewTrial) return (
        <Stack px={12} py={20} justifyContent="space-between">
            <Img w="md" src={mode(LightImage, DarkImage)} />
            <Reviews reviewMessage={reviewMessage} />
        </Stack>
    )

    return (
        <Stack px={12} py={20} justifyContent="space-between">
            <Stepper index={activeStep} orientation='vertical' height='180px' size="md">
                {steps.map((step, index) => (
                    <Step key={index}>
                        <StepIndicator borderColor="rarchy-border" bgColor={index === 0 ? "rarchy-link" : "unset"}>
                            {index === 0 && <CalendarIcon color="rarchy-bg-white" fontSize="15px" />}
                            {index === 1 && <FaRegBell color="rarchy-color-sitemap-editor" fontSize="18px" />}
                            {index === 2 && <MdTipsAndUpdates color="#ECC94B" fontSize="sm" />}
                            {index < 2 && (
                                <Progress
                                    orientation='vertical'
                                    value={index === 0 ? 50 : 0}
                                    bgColor="rarchy-border"
                                    position='absolute'
                                    height='4px'
                                    width='38px'
                                    top='48px'
                                    transform='rotate(90deg)'
                                />
                            )}
                        </StepIndicator>
                        <Box>
                            <StepTitle fontSize="sm" mb={1.5}>{step.title}</StepTitle>
                            <StepDescription fontSize="xs" color="fg.muted">{step.description}</StepDescription>
                        </Box>
                    </Step>
                ))}
            </Stepper>
            <Reviews reviewMessage={reviewMessage} />
        </Stack>
    )
}

const Reviews = ({ reviewMessage }) => {
    return (
        <Stack fontStyle="italic" fontSize="sm" spacing={5}>
            <Stack spacing={2}>
                <Box color="fg.muted">"{reviewMessage}"</Box>
                <Box color="fg.subtle">- Customer Review, Capterra</Box>
            </Stack>
            <Link isExternal target="_blank" href="https://www.capterra.com/reviews/203062/Rarchy">
                <Img w="125px" alt="Capterra Badge" src="https://assets.capterra.com/badge/b24c6cb6a5359ea3fc01d83d853ca690.svg?v=2145314&p=203062" />
            </Link>
        </Stack>
    )
}

const reviews = [
    { plan: 'pro', message: "The drag and drop functionality is great and the ability to add content blocks, notes, and add example wireframes really speeds up the [website] planning process" },
    { plan: 'pro', message: "The automatic sitemap generation along with the ability to have screenshots created automatically for each page makes it so easy to share visualisations between teams." },
    { plan: 'pro', message: "It was really easy to communicate the nuances of [our] site structure to the team." },
    { plan: 'enterprise', message: "Our team needed a tool to analyze large sites with approximately 2,000+ pages. We tried other tools and finally found that Rarchy has that bandwidth to crawl large sites and get the sitemap for all the pages." },
    { plan: 'enterprise', message: "Rarchy is an incredibly useful tool for conceptualizing and mapping the redesign of our robust and complex website. It positions us well to be able to identify pain points and growth areas in ways we could communicate." },
    { plan: 'enterprise', message: "Rarchy has been a life saver tool in the process of assessing the size of our current website to plan a better architecture moving forward." },
    { plan: 'enterprise', message: "Implementing it was a breeze because others could see the value right away. Now it's the go- to platform, used by me and others in the agency." },
    { plan: 'enterprise', message: "Intuitive, easy to use and most importantly did the job I needed it to do. Gave me an org chart for a huge site of over 14,000 pages." },

]
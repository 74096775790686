import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { SplitModal } from "../../Components/Modals/SplitModal"
import { toggleNewUserFlowModal, toggleUpgradeModal } from "../../../store/actions/ui-actions"
import { Box, Button, FormControl, FormLabel, Image, Input, Stack, useBoolean } from "@chakra-ui/react"
import { MainHeader } from "../../Settings/Components"
import { useHistory } from "react-router-dom"
import { getFirestore, doc, writeBatch } from "firebase/firestore"
import { useGetFolderIdFromPath, useGetUserId, useHasFreeUserCreatedFile } from "../../../hooks"
import { SelectSitemap } from "../../Editor/Toolbar/User-Flows/Linked/SelectSitemap"
import { wait } from "../../../../shared/helpers"
import { mergeFileChange } from "../../../store/actions/files-actions"
import { getNewFirestoreDocId, sendHubspotCustomEvent } from "../../../helpers"

const FONT_SIZE = "sm"
const FONT_WEIGHT = "medium"
const MB = 2

const UserFlowsImage = "https://help.rarchy.com/hubfs/User%20Flows%20-%20Light-min%20-%201080.png"

export const NewUserFlowModalContainer = () => {
    const dispatch = useDispatch()
    const [name, setName] = useState()
    const [sitemap, setSitemap] = useState()
    const hitFileLimit = useHasFreeUserCreatedFile('user-flows')
    useEffect(() => {
        if (hitFileLimit) {
            dispatch(toggleNewUserFlowModal())
            dispatch(toggleUpgradeModal({ showing: true, planId: 'pro', screen: 'new-file_user-flows' }))
        }
    }, [])
    if (hitFileLimit) return null
    return (
        <SplitModal
            isOpen
            isCentered
            onClose={() => dispatch(toggleNewUserFlowModal())}
            size="4xl"
            h="lg"
            //isFull
            left={(
                <Stack p={9} px={7} w="400px" spacing={5}>
                    <MainHeader fontSize="2xl" title="Create user flow" />
                    <Stack mt={4} spacing={8}>
                        <FormControl>
                            <FormLabel fontSize={FONT_SIZE} fontWeight={FONT_WEIGHT} mb={MB}>Name</FormLabel>
                            <Input autoFocus fontSize={FONT_SIZE} placeholder="Name your user flow" onChange={(e) => { setName(e.target.value) }} />
                        </FormControl>
                        <FormControl>
                            <FormLabel fontSize={FONT_SIZE} fontWeight={FONT_WEIGHT} mb={MB}>Link sitemap (optional)</FormLabel>
                            <SelectSitemap forNew menuPortalTarget={null} onChange={setSitemap} />
                        </FormControl>
                    </Stack>
                    <CreateButton name={name} sitemap={sitemap} />
                </Stack>
            )}
            right={(
                <Box px={12} mt={28}>
                    <Image
                        src={UserFlowsImage}
                        w="full"
                    />
                </Box>
            )}
        />
    )

}

const CreateButton = ({ name, sitemap }) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const [isCreating, setIsCreating] = useBoolean()
    const userId = useGetUserId()
    const folderId = useGetFolderIdFromPath({ returnAccountId: true });
    return (
        <Button
            colorScheme="blue"
            mt={8}
            w="full"
            isLoading={isCreating}
            onClick={async () => {

                setIsCreating.on()

                try {

                    const firestore = getFirestore()

                    // Get a new write batch
                    const batch = writeBatch(firestore);

                    // create doc data
                    const newDocData = {
                        archived: false,
                        createdAt: new Date(),
                        createdBy: userId,
                        updatedAt: new Date(),
                        updatedBy: userId,
                        name: name || "Untitled User Flow",
                        team: folderId,
                        sitemap: sitemap || null
                    };

                    const docId = getNewFirestoreDocId("user-flows");

                    // set doc
                    const docRef = doc(firestore, "user-flows", docId);
                    batch.set(docRef, newDocData, { merge: true });

                    // set elements
                    const elements = {
                        lastEdit: userId,
                        elements: {
                            "gkp8rej1fp": { "connectors": { "right": { "karg8vy8uc": { "position": "left" } } }, "text": "Drag from markers to create connectors between symbols", "type": "startEnd", "x": 881.25, "y": 110.41666666666666 }, "h8xb4zmgui": { "connectors": { "right": { "gkp8rej1fp": { "position": "left" } } }, "text": "Click on the canvas, drag from the toolbar, or add from existing symbol", "type": "action", "x": 450, "y": 110.41666666666666 }, "karg8vy8uc": { "text": "Click on symbols & connections to show options", "type": "inputOutput", "x": 1278.75, "y": 110.41666666666666 }
                        },
                        updatedAt: new Date(),
                    }
                    const elementsRef = doc(docRef, "data", "elements")
                    batch.set(elementsRef, elements, { merge: true })

                    // commit batch to firestore
                    await batch.commit()

                    await wait(1000)
                    
                    // insert new file into files
                    dispatch(mergeFileChange(docId, 'user-flows', { id: docId, collection: 'user-flows', ...newDocData }))
                    
                    history.push(`/app/flow/${docId}`)

                    dispatch(toggleNewUserFlowModal({ showing: false }))

                    // send event to Hubspot
                    sendHubspotCustomEvent('created_user_flow')

                } catch (e) {
                    console.error(e)
                    return;
                } finally {
                    setIsCreating.off()
                }

            }}>
            Create
        </Button>
    )
}
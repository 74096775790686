import { store } from '../../../../store';
import { update, getPageDataAsObjFromNode, createNodeId } from '../app';
import cloneNode from 'clone';
import { hitPersonalSitemapPageLimit } from './upgrade';
import { addPageChange, addCoversChange, mergePagesEdits, mergeCoversEdits } from '../../../../store/actions/sitemap-actions';


// import amplitude from 'amplitude-js/amplitude';

import { handlePageSectionsFromPageChange } from '../../app/canvas/utils/page-sections/helpers';
import { toggleUpgradeModal } from '../../../../store/actions/ui-actions';
import { getSitemap } from '../../../../helpers';

import { isEmpty, cloneDeep, omitBy, omit, isUndefined } from 'lodash'
import { chain } from '../../../../helpers/chain';

/**** CLONE ****/
export const clone = function (node, i) {

  /*** ensure user in free sitemap can not go over 50 pages ***/
  const pagesToCloneCount = getChildCount(node);
  const hasHitPageCount = hitPersonalSitemapPageLimit(pagesToCloneCount);
  if (hasHitPageCount) return store.dispatch(toggleUpgradeModal({ showing: true, planId: 'pro', screen: 'pages-limit' }));
  /*** ensure user in free sitemap can not go over 50 pages ***/

  // sitemap
  const sitemap = getSitemap();
  //
  const newParent = node.parent;
  // clone object
  var newNode = cloneNode(node);

  newNode.parent = newParent;
  newNode.id = createNodeId();
  newNode.x0 = node.x;
  newNode.y0 = node.y;
  newParent.children.splice(newNode.index + 1, 0, newNode);


  // covers data for firestore
  var clonedCoversData = {};
  /*** do covers for initial cloned node ***/
  if (sitemap?.covers.pages[node.id]) {
    if (sitemap?.covers.pages[node.id].collection) {
      clonedCoversData[newNode.id] = { collection: sitemap?.covers.pages[node.id].collection };
    }
  }
  /*** do covers for initial cloned node ***/

  // sections data for firestore
  var clonedPageSectionsPages = {};
  /*** do page sections for initial cloned node ***/
  if (!isEmpty(sitemap?.data.page_sections[node.id])) {
    // ensure new id is set for each section 
    clonedPageSectionsPages[newNode.id] = cloneDeep(sitemap?.data?.page_sections?.[node.id]
      ?.map((s, i) => {
        return { ...s, id: Date.now() - i }
      }));
    newNode.pageSections = clonedPageSectionsPages[newNode.id]
  }
  /*** do page sections for initial cloned node ***/

  recurse(newNode);

  function recurse(d) {
    var children = d.children ? d.children : d._children;
    var length = !children ? 0 : children.length;
    for (var i = 0; i < length; i++) {
      children[i].parent = d;
      const newChildId = createNodeId();
      /*** do covers for all other nodes ***/
      if (sitemap?.covers.pages[children[i].id]) {
        if (sitemap?.covers.pages[children[i].id].collection) {
          clonedCoversData[newChildId] = { collection: sitemap?.covers.pages[children[i].id].collection };
          children[i].cover = { collection: sitemap?.covers.pages[children[i].id].collection };
        }
      }
      /*** do covers for all other nodes ***/
      /*** do page sections for all other nodes ***/
      if (!isEmpty(sitemap?.data.page_sections[children[i].id])) {
        clonedPageSectionsPages[newChildId] = cloneDeep(sitemap?.data.page_sections[children[i].id]);
      };
      /*** do page sections for all other nodes ***/
      children[i].id = newChildId;
      recurse(children[i]);
    }
  }

  // keep page open
  if (newNode.children) localStorage.setItem(`${sitemap?.id}#${newNode.id}`, true);

  // update
  update();

  // getPageDataAsObjFromNode returns map of objects - need to convert to array to save in firestore
  var pages = chain(getPageDataAsObjFromNode(newNode, { cloning: true })).map((obj, id) => {
    return Object.assign({}, obj, { id });
  }).value();

  /*** set new indexes for existing children of same parent ***/
  const childrenWithNewIndexes = [];
  newNode.parent.children.forEach((d, i) => { if (d.id !== newNode.id) childrenWithNewIndexes.push({ id: d.id, index: i }) });
  /*** set new indexes for existing children of same parent ***/
  /*** set old indexes for existing children of same parent ***/
  const childrenWithOldIndexes = [];
  newNode.parent.children.filter(d => d.id !== newNode.id).forEach((d, i) => { childrenWithOldIndexes.push({ id: d.id, index: i }) });
  /*** set old indexes for existing children of same parent ***/

  pages.forEach(page => {

    /*** get page sections if exist ***/
    if (clonedPageSectionsPages[page.id]) page.pageSections = clonedPageSectionsPages[page.id];
    /*** get page sections if exist ***/

    /*** get covers if from wireframe collection ***/
    if (clonedCoversData[page.id]) { if (clonedCoversData[page.id].collection) page.cover = clonedCoversData[page.id]; }
    /*** get covers if from wireframe collection ***/

  });

  /*** merge cover edits ***/
  store.dispatch(mergeCoversEdits({ pages: { ...sitemap?.covers?.pages, ...clonedCoversData } }))
  /*** merge cover edits ***/

  /*** merge page edits ***/
  const pagesObj = {};
  pages.forEach(d => pagesObj[d.id] = omitBy(omit(d, ['id']), isUndefined));
  childrenWithNewIndexes.forEach(c => pagesObj[c.id] = { ...sitemap?.docs.pages[c.id], index: c.index });
  store.dispatch(mergePagesEdits({ pages: /* merge(sitemap?.docs.pages, */pagesObj/*)*/ }))
  /*** merge page edits ***/

  // save user change
  const change = { id: new Date().getTime(), data: [...pages, ...childrenWithNewIndexes] };
  // save page section changes
  if (!isEmpty(clonedPageSectionsPages)) handlePageSectionsFromPageChange('clone', { pages: clonedPageSectionsPages });
  // save cover changes
  const coversToClone = { id: new Date().getTime(), data: [clonedCoversData] }
  // clone page covers
  if (!isEmpty(coversToClone.data)) { setTimeout(() => { store.dispatch(addCoversChange({ change: coversToClone, covers: clonedCoversData })) }, 150); }
  //
  const history = { action: 'clone', data: pages, childrenWithOldIndexes, childrenWithNewIndexes };
  //
  setTimeout(() => { store.dispatch(addPageChange({ change, history })); }, 500);
  // amplitude tracking
  // amplitude.getInstance().logEvent('SITEMAP_CLONED_PAGE', { id: sitemap?.id });
};
/**** CLONE ****/

function getChildCount(d) {
  var count = 0;
  var children = d.children ? d.children : d._children;
  var length = !children ? 0 : children.length;
  for (var i = 0; i < length; i++) {
    count++;
    getChildCount(children[i]);
  }
  return count;
};

import { Box, Button, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger, Portal, Stack, Text } from "@chakra-ui/react"
import { toggleNewSitemapModal, toggleUpgradeModal } from "../../../../../store/actions/ui-actions"
import { useCanStartNewTrial, useGetPagesImportLimitByPlan, useIsTrialing } from "../../../../../../shared/hooks/pricing"

import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { useInAgencyPlan } from "../../../../../hooks"

export const UpgradePopover = ({ finishedEarly, forWhy, data, button, isCrawl }) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const inAgencyPlan = useInAgencyPlan()
    const canStartNewTrial = useCanStartNewTrial()
    const isTrialing = useIsTrialing()
    const showPopover = finishedEarly || forWhy
    const enterprise_pages_per_import = useGetPagesImportLimitByPlan({planId: 'enterprise'})
    return (
        <Popover trigger="hover" openDelay={250}>
            <PopoverTrigger>
                {button}
            </PopoverTrigger>
            {showPopover && (
                <Portal>
                    <PopoverContent mt={1}>
                        <PopoverArrow />
                        <PopoverBody py={3}>
                            <Stack>
                                <Box fontSize="sm" fontWeight="normal" color="fg.muted">
                                    <>
                                        {finishedEarly && `We stopped ${isCrawl ? "crawl" : "import"}ing because we hit your import limit of ${data?.retrieved?.limit?.toLocaleString()} pages per sitemap.`}
                                        {forWhy && `We'll stop ${isCrawl ? "crawl" : "import"}ing when we hit your import limit of ${data?.retrieved?.limit?.toLocaleString()} pages per sitemap.`}
                                    </>
                                </Box>
                                {!inAgencyPlan && (
                                    <Box fontSize="sm" fontWeight="normal" color="fg.muted">
                                        <Button
                                            mr={1.5}
                                            fontWeight="medium"
                                            variant="link"
                                            fontSize="sm"
                                            color="rarchy-link"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                dispatch(toggleUpgradeModal({ showing: true, planId: 'enterprise', screen: 'pages-per-import', lockPlan: true }))
                                            }}
                                        >
                                            <>
                                                {!isTrialing && canStartNewTrial && "Start a free Rarchy Enterprise trial"}
                                                {!canStartNewTrial && "Upgrade to Rarchy Enterprise"}
                                            </>
                                        </Button>
                                        {`to ${isCrawl ? "crawl" : "import"} up to ${enterprise_pages_per_import?.toLocaleString()} pages per sitemap.`}
                                    </Box>
                                )}
                                <Box
                                    fontSize="sm"
                                    fontWeight="normal"
                                    color="fg.muted"
                                >
                                    <Text display="inline" mr={.5}>Need more pages? </Text>
                                    <Button
                                        mr={1.5}
                                        fontWeight="normal"
                                        color="unset"
                                        variant="link"
                                        fontSize="sm"
                                        textDecor="underline"
                                        textUnderlineOffset={3}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            dispatch(toggleNewSitemapModal({ showing: false }))
                                            history.push('/app/help/ticket');
                                        }}
                                    >
                                        Get in touch
                                    </Button>
                                </Box>
                            </Stack>
                        </PopoverBody>
                    </PopoverContent>
                </Portal>
            )}
        </Popover>
    )
}
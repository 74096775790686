import { Box, Menu as ChakraMenu, Divider, MenuButton, MenuDivider, MenuList, SlideFade, useColorModeValue as mode } from "@chakra-ui/react"
import { MenuItem, createMenuItems } from './MenuItem'
import React, { useEffect, useRef, useState } from 'react'
import { useCanCommentOnFile, useCanEditFile, useGetSitemap } from '../../../../../hooks'
import { useDispatch, useSelector } from 'react-redux'

import { FaRegFolderOpen } from 'react-icons/fa'
import { PageColorScreen } from './Color'
import { selectAction } from '../actions'

export const Menu = () => {

    const dispatch = useDispatch()

    const sitemap = useGetSitemap()

    const canEdit = useCanEditFile()
    const canComment = useCanCommentOnFile()
    
    const menuItems = createMenuItems({ sitemap, canEdit, canComment })

    const menuColor = mode('gray.600', 'gray.100')
    const [showPageColorScreen, setShowPageColorScreen] = useState(false)
    //
    const [height, setHeight] = useState(0)
    const [overlap, setOverlap] = useState(null)
    const ref = useRef(null)
    useEffect(() => {
        if (!height && ref.current) {
            if (ref.current.getBoundingClientRect().y + ref.current.getBoundingClientRect().height > window.innerHeight) {
                setOverlap([sitemap?.ui.ContextMenuDropdown.offset[0], sitemap?.ui.ContextMenuDropdown.offset[1] - ref.current.clientHeight])
            }
            setHeight(ref.current.clientHeight)
            // console.log(height);
        }
    }, [height, sitemap?.ui.ContextMenuDropdown.offset])
    // unmount
    useEffect(() => () => setShowPageColorScreen(false), []);
    //
    const { ContextMenuDropdown } = sitemap?.ui || {}
    const { node } = ContextMenuDropdown || {}

    const handleSelect = (e, id) => {
        if (id !== 'color') return selectAction({ e, id, node, sitemap, dispatch })
            e.stopPropagation()
        setShowPageColorScreen(true)
    }
    if (!node) return null;
    return (
        <Box onClick={(e) => e.stopPropagation()}>
            <ChakraMenu innerRef={ref} isOpen={ContextMenuDropdown.showing} offset={overlap ? overlap : ContextMenuDropdown.offset}>
                <MenuButton />
                {!showPageColorScreen && <MenuList color={menuColor} minW={-1} w="185px">
                    <>
                        <MenuItem key="open-drawer" id="open-drawer" icon={<FaRegFolderOpen fontSize="1.4em" />} text="Open Page Drawer" onClick={handleSelect} />
                        <MenuDivider />
                    </>
                    {menuItems.map(item => <MenuItem key={item.id} id={item.id} divider={item.divider} icon={item.icon} text={item.text} submenu={item.submenu} onClick={(e, id) => handleSelect(e, id)} />)}
                </MenuList>}
                {showPageColorScreen && (
                    <MenuList minW={-1} w="210px" px={0}>
                        <PageColorScreen setShowPageColorScreen={setShowPageColorScreen} />
                    </MenuList>
                )}
            </ChakraMenu>
        </Box>
    )
}
import { useEffect } from "react"
import { Badge, Button, useBoolean } from "@chakra-ui/react"
import { useGetSubscription, usePrevious } from "../../../../hooks"

import { useGetAnnualDiscount, useGetPriceWithCurrencySymbol, useIsBilledMonthly } from "../../../../../shared/hooks/pricing"
import { useFirebase } from "react-redux-firebase"
import { ArrowForwardIcon } from "@chakra-ui/icons"

import queryString from 'query-string';
import { wait } from "../../../../../shared/helpers"
import { handleCustomerPortalSubscriptionUpdate } from "./Subscriptions"
import { useGetPlanId } from "../../../../../shared/hooks"

export const UpgradeAnnual = ({ price }) => {

    const { upgrade } = queryString.parse(window?.location.search) || {}

    const firebase = useFirebase()
    const [isLoading, setIsLoading] = useBoolean(false)

    const annualPlanId = `${useGetPlanId()?.replace('team', 'enterprise')}0`

    const annualDiscount = useGetPriceWithCurrencySymbol(useGetAnnualDiscount({ price }))
    const isBilledMonthly = useIsBilledMonthly()
    const subscription = useGetSubscription()

    const { customer_id, id: subscription_id } = subscription

    const prevCustomerId = usePrevious(customer_id)

    // redirect to stripe if ?upgrade=annual
    useEffect(() => {
        if (upgrade === 'annual') if (customer_id && !prevCustomerId) goToStripe()
    }, [customer_id, prevCustomerId])

    const goToStripe = async () => {
        try {
            setIsLoading.on()
            const sessionURL = await firebase.functions().httpsCallable('stripe-createCustomerPortalSession')({ return_url: window?.location?.href?.split("?")?.[0], customerId: customer_id, flow_data: { type: "subscription_update_confirm", subscription_update_confirm: { subscription: subscription_id, items: [{ id: subscription?.plan?.subscription_id, price: annualPlanId, quantity: 1 }] } } })
            if (!sessionURL?.data) { // fallback to change plan in case there is an error
                await handleCustomerPortalSubscriptionUpdate({ setIsLoading, customer_id, subscription_id })
            } else {
                await wait(1000)
                window.location = sessionURL.data
            }
        } catch (e) {
            console.error(e)
        } finally {
            setTimeout(() => setIsLoading.off(), 2000);
        }
    }

    return (
        isBilledMonthly ? <Button
            isLoading={isLoading}
            loadingText="Loading..."
            mt={-4}
            ml={-2}
            variant="ghost"
            colorScheme="blue"
            fontWeight="medium"
            size="sm"
            leftIcon={<Badge colorScheme="green" mt="1px" p={1.5} py={0.5} textTransform="capitalize" fontWeight="medium">{`Save ${annualDiscount}`}</Badge>}
            rightIcon={<ArrowForwardIcon />}
            onClick={() => goToStripe()}
        >
            per year with annual billing
        </Button> : null
    )
}
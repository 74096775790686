import { useDispatch } from "react-redux"
import { Stack } from "@chakra-ui/react"
import { HomeSectionHeader } from "../.."
import { FaRegFileCode, FaSpider } from "react-icons/fa"
import { TbSitemap, TbUsersPlus } from "react-icons/tb"
import { RiFlowChart } from "react-icons/ri"
import { HiOutlineTemplate } from "react-icons/hi"
import { useHasTeamMembers, useHasUserCreatedFile, useInOrganization, useIsCreatorOfOrganization } from "../../../../hooks"
import { toggleAddUsersModal, toggleNewSitemapModal, toggleNewUserFlowModal, toggleUpgradeModal } from "../../../../store/actions/ui-actions"
import { ButtonRadioGroup } from "./Buttons/Components/ButtonRadioGroup"
import { TRIAL_DAYS, useCanStartNewTrial } from "../../../../../shared/hooks/pricing"
import { MdTipsAndUpdates } from "react-icons/md"
import { SkeletonWrapper } from "../../../Components/Shared/SkeletonWrapper"
import { useMemo } from "react"
import { compact, uniq } from 'lodash'

export const WantToTry = () => {

    const dispatch = useDispatch()


    const isCreatorOfOrg = useIsCreatorOfOrganization({ onlyActive: true })
    const inOrganization = useInOrganization()

    const canStartNewTrial = useCanStartNewTrial()

    const hasUserCreatedFile = useHasUserCreatedFile()
    const hasTeamMembers = useHasTeamMembers()


    const crawlOption = {
        description: 'Crawl website to create fresh sitemap',
        icon: <FaSpider />,
        value: 'crawl',
        onClick: () => dispatch(toggleNewSitemapModal({ showing: true, createType: 'crawl' }))
    }

    const importOption = {
        description: 'Import an existing sitemap from XML',
        icon: <FaRegFileCode />,
        value: 'import',
        onClick: () => dispatch(toggleNewSitemapModal({ showing: true, createType: 'xml' }))
    }

    const newSitemapOption = {
        description: 'Create a new sitemap from scratch',
        icon: <TbSitemap />,
        value: 'new-sitemap',
        onClick: () => dispatch(toggleNewSitemapModal({ showing: true, createType: 'new' }))
    }

    const newUserFlowOption = {
        description: 'Create a user flow from scratch',
        icon: <RiFlowChart />,
        value: 'new-user-flow',
        onClick: () => dispatch(toggleNewUserFlowModal({ showing: true }))
    }

    const templatesOption = {
        description: 'Browse our template collection',
        icon: <HiOutlineTemplate />,
        value: 'templates',
        link: "/app/templates"
    }

    const upgradeOption = !isCreatorOfOrg ? {
        description: canStartNewTrial ? `Try Rarchy Pro free for ${TRIAL_DAYS} days` : 'Upgrade to Rarchy Pro. Cancel anytime.',
        icon: <MdTipsAndUpdates color="#ECC94B" />,
        value: 'upgrade',
        onClick: () => dispatch(toggleUpgradeModal({ showing: true, planId: 'pro' }))
    } : null

    const invitePeopleOption = {
        description: 'Invite people to join your Rarchy account',
        icon: <TbUsersPlus />,
        value: 'people',
        onClick: () => dispatch(toggleAddUsersModal({ showing: true }))
    }

    const options = useMemo(() => compact(uniq([
        !inOrganization && !hasUserCreatedFile && compact([
            crawlOption, importOption, newSitemapOption, newUserFlowOption, templatesOption
        ]),
        !inOrganization && hasUserCreatedFile && compact([
            upgradeOption, crawlOption, importOption, newSitemapOption, newUserFlowOption
        ]),
        inOrganization && !hasTeamMembers && compact([
            invitePeopleOption, newUserFlowOption, crawlOption, importOption, newSitemapOption
        ]),
        inOrganization && hasTeamMembers && compact([
            newUserFlowOption, invitePeopleOption, crawlOption, importOption, newSitemapOption
        ])
    ]))?.[0], [])

    return (
        <Stack spacing={6}>
            <SkeletonWrapper w="fit-content">
                <HomeSectionHeader title="You might want to try..." />
            </SkeletonWrapper>
            <ButtonRadioGroup options={options} />
        </Stack>
    )
}
import { ascending, stratify as d3Stratify, tree as d3Tree, hierarchy, select } from 'd3';
import { compact, groupBy, isEmpty, keyBy, orderBy, partition, remove, sortBy } from 'lodash'
import { getRevisionHistoryDrawer, getSitemap } from '../../../helpers';

import { dragging } from '../app/canvas/utils/drag';
import { load as loadViews } from '../app/views';
import queryString from 'query-string';
import { store } from '../../../store';
import { togglePageButtons } from '../../../store/actions/sitemap-actions';

export const stratify =
  d3Stratify()
    .id(d => {
      return d.id
    })
    .parentId(d => {
      // continue
      let parent;
      if (d.parent) {
        parent = d.parent;
      } else {
      }
      return parent;
    });

export const createRoot = async (pages) => {

  const sitemap = getSitemap()
  const RevisionHistoryDrawer = getRevisionHistoryDrawer()

  const { website_sections: websiteSectionsDoc } = RevisionHistoryDrawer.showing ? RevisionHistoryDrawer?.docs : sitemap?.docs;

  // Assigns parent, children, height, depth
  const mainPagesOrWithSections = partition(pages, (p) => !p.website_section);
  const mainPages = !isEmpty(mainPagesOrWithSections[0]) ? mainPagesOrWithSections[0] : mainPagesOrWithSections[1];

  // website sections
  let website_sections = groupBy(mainPagesOrWithSections[1], 'website_section');
  const websiteSectionIds = Object.keys(website_sections);

  /*** clean up any website section issues ***/
  await Promise.all(websiteSectionIds?.map(async sectionId => {
    // filter out any homepages that have been inserted previously (fail-safe)
    website_sections[sectionId] = website_sections[sectionId].filter((s) => { return s.id !== s.website_section });
    // remove page if pages website_section doesn't exist in website_sections doc
    await Promise.all(website_sections[sectionId].map(async page => {
      if (!Object.keys(websiteSectionsDoc).includes(page?.website_section)) {
        mainPages.push({ ...page, parent: 'home', website_section: null }); // insert page into mains array under 'home'
        remove(website_sections[sectionId], { id: page.id }); // remove page from website_sections array
      }
    }));
  }));
  /*** clean up any website section issues ***/

  const root = await getRoot(mainPages);

  if (root) {
    // don't need website sections if in subfolder
    if (!isEmpty(mainPagesOrWithSections[0])) {
      // website sections
      root.website_sections = [];
      // continue
      await Promise.all(websiteSectionIds?.map(async (sectionId) => { // only adds to array if pages under website section
        const pagesInWebsiteSection = [{ id: sectionId, website_section: sectionId }, ...website_sections[sectionId]]; // merge in homepage using website-section id as id
        // ensure every page has parent
        pagesInWebsiteSection?.forEach(p => { if (!p.parent && (p.id !== p.website_section)) p.parent = p.website_section; })
        const sectionRoot = await getRoot(pagesInWebsiteSection);
        root.website_sections.push(sectionRoot);
      }));
    }
  }

  // return
  return root;

};

let errorCount = 0; // stops call stack size exceeding

const getRoot = async (pages) => {

  let root;

  try {

    const stratified = stratify(orderBy(pages, ['index'], ['asc']));
    root = hierarchy(stratified, d => d.children);
    //
    root.x0 = 0;
    root.y0 = 0;
    //
    await root.each((d, i) => {
      d.data = d.data.data;
      // needs to be line by line due to circular references
      d.name = d.data.name ? getPageName(d.data.name) : 'Page';
      d.index = d.data.index;
      d.id = d.data.id;
      d.url = d.data.url;
      d.pallette = d.data.pallette;
      d.website_section = d.data.website_section;
      // no longer need data
      delete d.data;

    });
    // collapses sitemap after second level (taking into account last user changes) (if not dragging)
    if (!dragging) {
      if (root.children) {
        for (var i = 0, len = root.children.length; i < len; i++) {
          open(root.children[i]);
        }
      }
    }
    //
  } catch (e) {
    console.error(e);
    if (errorCount > 1 || !e.message) return;
    errorCount++;
    const sitemap = getSitemap();
    if (e.message === 'multiple roots') {
      pages.map(page => {
        if (!page.parent && page.id !== 'home') {
          page.parent = 'home';
        }
        return page;
      });
      root = await createRoot(pages);
    } else if (e.message.startsWith('missing')) {
      const missingId = e.message.substring(e.message.lastIndexOf(':') + 1).replace(/ /g, '');
      root = await cleanUpMissingParent({ pages, missingId, sitemap });
    }
  }
  // THE MAGIC RETURN
  return root;
}

export function decodeURIComponentSafe(uri, mod) {
  var out = "",
    arr,
    i = 0,
    l,
    x;
  // eslint-disable-next-line
  typeof mod === "undefined" ? mod = 0 : 0;
  arr = uri.split(/(%(?:d0|d1)%.{2})/);
  for (l = arr.length; i < l; i++) {
    try {
      x = decodeURIComponent(arr[i]);
    } catch (e) {
      x = mod ? arr[i].replace(/%(?!\d+)/g, '%25') : arr[i];
    }
    out += x;
  }
  return out;
}

const cleanUpMissingParent = async ({ pages, missingId }) => {
  pages.map(page => {
    if (page.parent === missingId) {
      page.parent = !page.website_section ? 'home' : page.website_section;
    }
    // set parent as website_section if not "fake home"
    if (!page.parent && (page.website_section && page.id !== page.website_section)) {
      page.parent = page.website_section;
    }
    return page;
  });
  // pages.push({ name: 'Recovered Page', parent: "home", id: parentId });
  const root = await createRoot(pages);
  return root;
}

const open = d => {
  if (!d.parent) return;
  const sitemap = getSitemap();
  // open all pages if nodes variant
  const { v: variant } = queryString.parse(window.location.search);
  const fromNodesVariant = variant === '3ocwXL1hQFGiCXeSRcllNQ';
  //
  const pageOpen = fromNodesVariant ? true : localStorage.getItem(`${sitemap?.id}#${d.id}`);
  if (!pageOpen) {
    if (d.children) {
      d._children = d.children;
      for (var i = 0, len = d._children.length; i < len; i++) {
        open(d._children[i]);
      }
    }
    d.children = null;
  }
  if (pageOpen) {
    if (d.children) {
      for (var j = 0, length = d.children.length; j < length; j++) {
        open(d.children[j]);
      }
    }
  }
};
//

export const update = (opts) => {
  //
  const { editor } = store.getState();
  const sitemap = getSitemap();

  const { PageButtons } = sitemap?.ui || {}
  const { RevisionHistoryDrawer } = editor?.ui;

  const rootToUse = !RevisionHistoryDrawer.showing ? (sitemap?.data?.section || sitemap?.data?.root) : RevisionHistoryDrawer.data.root;

  if (!rootToUse) return;

  if (PageButtons?.showing) store.dispatch(togglePageButtons({ showing: false }))

  const { nodes, links, root } = setIndexesAndGetNodesLinks(rootToUse, sitemap);

  /*** all other views */
  loadViews({ nodes, links, root, opts })
  /*** all other views */
};

export const setIndexesAndGetNodesLinks = (root, sitemap) => {

  let nodes = [], links = [], rootWebsiteSections = [];

  const inSubfolder = sitemap?.data?.section;

  function recurse(r, section) {
    // set indexes for all nodes (visible and not visible)
    setIdsAndIndexes(r);
    // do tree
    const tree = d3Tree().nodeSize([0, 50]);
    var treeData = tree(r).sort((a, b) => ascending(a.index, b.index));
    // maps the node data to the tree layout
    links.push(compact(treeData
      .descendants()
      .slice(1)
      .map(l => {
        if (!inSubfolder) {
          if (r.website_section) {
            if (!l.parent.parent) return null
          } // don't show links from 1st level up
        }
        return l;
      })));
    // nodes
    treeData.eachBefore(n => nodes.push(n));
    // website sections
    if (!inSubfolder) {
      r.website_sections?.forEach(webSectRoot => {
        recurse(webSectRoot, section);
        rootWebsiteSections.push(webSectRoot);
      });
    }
  }

  recurse(root);

  if (!inSubfolder) root.website_sections = rootWebsiteSections;

  return { nodes, links: links.flat(1), root };
}

export const collapse = (d, noUpdate) => {

  if (!d.parent) return;

  const sitemap = getSitemap();

  const numberOfPages = Object.keys(sitemap?.docs.pages).length;

  function recurse(d) {
    if (d.children) {
      if (numberOfPages <= 5000) localStorage.removeItem(`${sitemap?.id}#${d.id}`);
      //
      d._children = d.children;
      for (var i = 0, len = d._children.length; i < len; i++) {
        if (numberOfPages <= 5000) localStorage.removeItem(`${sitemap?.id}#${d._children[i].id}`);
        recurse(d._children[i]);
      }
      d.children = null;
    }
  }
  recurse(d);
  //
  if (!noUpdate) update();
};

export const expandOnClick = d => {
  if (!d.parent) return;
  //
  const sitemap = getSitemap();
  const numberOfPages = Object.keys(sitemap?.docs.pages).length;

  if (numberOfPages <= 5000) localStorage.setItem(`${sitemap?.id}#${d.id}`, true);
  d.children = d._children;
  d._children = null;
  //
  update();
};

export const expandToNode = (paths, opts) => {

  const sitemap = getSitemap();
  const numberOfPages = Object.keys(sitemap?.docs.pages).length;

  if (isEmpty(opts)) opts = {}; // fail-safe

  for (var i = 0; i < paths.length; i++) {
    if (paths[i]._children) { // if children are hidden: open them, otherwise: don't do anything
      if (numberOfPages <= 5000) localStorage.setItem(`${sitemap?.id}#${paths[i].id}`, true);
      paths[i].children = paths[i]._children;
      paths[i]._children = null;
    }
    // reached target node
    if (i === paths.length - 1) {
      const { searched, comments } = opts;
      if (searched) paths[i].searched = true;
      if (comments) paths[i].goToComments = true;
      update();
      return paths[i];
    }
  }

};

export const createNodeId = () => Math.random().toString(36).substr(2, 10)

/***** SET INDEXES *****/
export function setIdsAndIndexes(root) {
  //
  setIndex(root);
  //
  sortChildren(root);
  //
  function setIndex(node) {
    if (!node) return;
    // set ids
    if (!node.id || node.id === undefined) {
      node.id = createNodeId();
    }
    //
    if (node.children) {
      node.children.forEach((d, i) => {
        d.index = i;
        setIndex(d);
      });
    }
  };
  //
  function sortChildren(root) {
    const recurse = node => {
      if (!node) return;
      if (node.children) {
        node.children = orderBy(
          node.children,
          ['overflow', 'index'],
          ['desc', 'asc']
        );
        node.children.forEach(recurse);
      }
    };
    recurse(root);
  };
}
/***** IDS AND INDEXES *****/

export function wrapText(id, width) {
  var self = select(`#text${id}`),
    textLength = self.node().getComputedTextLength(),
    text = self.text();
  while (textLength > width - 2 && text.length > 0) {
    text = text.slice(0, -1);
    self.text(text + '...');
    textLength = self.node().getComputedTextLength();
  }
}

export const getSectionId = node => {
  // section is home
  if (!node.parent || !node.parent.parent) return 'home';
  // find section from ancestors
  const ancestors = node.parent.ancestors();
  const section = ancestors.reverse()[1].id;
  return section;
};

export const getPageDataAsObjFromNode = (node, opts = {}) => {

  const { cloning } = opts;

  var nodes = [];
  //
  recurse(node);
  //
  return keyBy(sortBy(nodes, 'index'), 'id');
  //

  function recurse(d) {
    if (!d) return;
    try {
      nodes.push({
        name: getPageName(d.name),
        parent: node.id === d.id && !cloning ? null : d.parent?.id, // this is for sections
        index: d.index,
        id: d.id,
        url: d.url,
        pallette: d.pallette ? d.pallette : undefined,
        website_section: d.website_section,
        website_sections: d.website_sections
      });
      d.website_sections?.forEach(r => recurse(r)); // also expand website sections
      if (d.children) d.children.forEach(recurse);
      if (d._children) d._children.forEach(recurse);
    } catch (e) {
      console.error(e);
    }
  }
};

export const getNodeById = (root, id) => {
  var data = {};
  function recurse(node) {
    if (node.children) node.children.forEach(recurse);
    if (node._children) node._children.forEach(recurse);
    if (node.id === id) {
      data = node;
      return;
    }
  }
  recurse(root ? root : store.getState().sitemap?.data.root);
  root.website_sections?.forEach(r => recurse(r)); // also search website sections
  return data;
};

export const fixDuplicateIndexes = (pages) => {
  for (let i = 0; i < pages.length; i++) {
    const page = pages[i];
    const nextPage = pages[i + 1];
    if (nextPage) {
      if (page.index === nextPage.index && page.parent === nextPage.parent) {
        page.index++;
      }
    }
  }
  return pages;
}

export const getPageName = (name) => {
  if (!name) return '';
  return decodeURIComponentSafe(name) || '';
}
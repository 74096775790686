import {Checkbox, HStack, IconButton, Text} from "@chakra-ui/react"
import React, { useEffect, useState } from 'react'
import { addPageChange, mergePagesEdits } from '../../../../../store/actions/sitemap-actions'

import { ArrowBackIcon } from "@chakra-ui/icons"
import { ColorsLabels } from "../../../../Editor/Toolbar/Colors/Components/ColorsLabels"
import { getCanEditInEditor } from '../../../../Editor/Navbar/helpers'
import { getDefaultPalletteHeader } from '../../../../../helpers'
import { isEmpty } from 'lodash'
import { update } from '../../../utils/app'
import { useDispatch } from 'react-redux'
import { useFirestore } from 'react-redux-firebase'
import { useGetSitemap } from '../../../../../hooks'

export const PageColorScreen = ({ setShowPageColorScreen }) => {

    const sitemap = useGetSitemap()

    const { ContextMenuDropdown } = sitemap?.ui || {}
    const { node } = ContextMenuDropdown || {}

    const inputColor = node && !isEmpty(node.pallette) ? node.pallette.header : getDefaultPalletteHeader()

    const [inputVal, setInputVal] = useState(inputColor)
    const [children, setChildren] = useState(node?.pallette?.children || false)

    /*** unmounting (save) ***/
    const firestore = useFirestore()
    const dispatch = useDispatch()
    useEffect(() => () => {
        savePageColorChange({ node, sitemap, firestore, dispatch });
    }, [])
    /*** unmounting (save) ***/

    if (!node) return null

    const handlePalletteChange = ({ header }) => {
        node.pallette = header ? { header, children: node?.pallette?.children || false } : null;
        setInputVal(header || getDefaultPalletteHeader());
        update();
    }
    
    const handleChildPages = (e) => {
        node.pallette = { header: inputVal, children: e.target.checked };
        setChildren(e.target.checked);
        update();
    }

    return (
        <>
            <HStack justifyContent="space-between" px={2} mb={1}>
                <IconButton variant="ghost" size="xs" icon={<ArrowBackIcon fontSize="lg" />} onClick={(e) => {
                    e.stopPropagation()
                    setShowPageColorScreen(false)
                }} />
                <Checkbox size="sm" mr={2} isChecked={children} value={children} onChange={handleChildPages}>
                    <Text mt="-1px" color="fg.muted" fontSize="13px">
                        Include subpages
                    </Text>
                </Checkbox>
            </HStack>
            <ColorsLabels
                selectedColor={node?.pallette?.header}
                handlePalletteChange={handlePalletteChange}
            />
        </>
    )
}

export const savePageColorChange = ({ node, sitemap, firestore, dispatch }) => {

    const canEdit = getCanEditInEditor();

    // ensure user can save
    if (/*showUpgradeMessaging || */!canEdit) return;

    /*** no changes ***/
    const pageFromSitemapPagesDoc = sitemap?.docs.pages[node.id];
    if (!node || !pageFromSitemapPagesDoc) return;
    if (!node.pallette && !pageFromSitemapPagesDoc.pallette) return;
    if (node.pallette) {
        if (pageFromSitemapPagesDoc && !isEmpty(pageFromSitemapPagesDoc.pallette)) {
            if (node?.pallette?.header === pageFromSitemapPagesDoc.pallette.header) {
                const palletteChildren = pageFromSitemapPagesDoc.pallette.children === true ? true : false;
                const childrenChecked = node.pallette.children === true ? true : false;
                if (palletteChildren === childrenChecked) return;
            }
        }
    }
    /*** no changes ***/

    const pallette = !node.pallette ? firestore.FieldValue.delete() : { header: node?.pallette?.header !== sitemap?.pallette?.header ? node.pallette.header : firestore.FieldValue.delete(), children: node.pallette.children === true ? true : firestore.FieldValue.delete() } // ensure children is a bool
    // save
    var obj = [{ action: 'color', id: node.id, pallette }];
    const change = { id: new Date().getTime(), data: obj };
    const history = {
        action: 'color',
        node: node.id,
        data: { pallette, oldPallette: !isEmpty(pageFromSitemapPagesDoc.pallette) ? { header: pageFromSitemapPagesDoc.pallette.header, children: pageFromSitemapPagesDoc.pallette.children === true ? true : firestore.FieldValue.delete() } : null }
    };
    // send to GA
    const gaObj = { [`ga_event`]: { category: "Sitemap Interactions", action: `Page Color: ${typeof pallette.header === 'string' ? pallette.header : ''}${pallette.children === true ? ' (All child pages)' : ''}` } };
    window.dataLayer.push({ event: 'generic_ga_event', ...gaObj });
    // add page change in redux
    dispatch(addPageChange({ change, history }));

    // has to be here otherwise oldPallette above won't be accurate
    /*** merge page edits ***/
    dispatch(mergePagesEdits({ pages: { [node.id]: { ...pageFromSitemapPagesDoc, pallette: node?.pallette?.header ? { header: node?.pallette?.header, children: node?.pallette?.children } : null } } }))
    /*** merge page edits ***/

}
import { MenuItem, useBoolean } from "@chakra-ui/react"
import { ICON_SIZE, PY } from "../../../Projects/Folders/Components/Options/Menu"
import { CopyIcon } from "@chakra-ui/icons"
import { useInEditor, useIsUserFile } from "../../../../hooks"
import { mergeFileChange } from "../../../../store/actions/files-actions"
import { useDispatch } from "react-redux"
import { MenuItemSpinner } from "../../../Components/Shared/MenuItemSpinner"
import { getFirestore, doc, getDoc, writeBatch } from "firebase/firestore"
import { getEditorSubfolderFromDoc, getNewFirestoreDocId, getUserId } from "../../../../helpers"
import { getFirebase } from "react-redux-firebase"
import { toggleUpgradeModal } from "../../../../store/actions/ui-actions"
import { omit } from 'lodash'

export const CopyFile = ({ file, onClose }) => {
    const dispatch = useDispatch()
    const [isCopying, setIsCopying] = useBoolean()
    const isUserFile = useIsUserFile(file)
    const inEditor = useInEditor()
    const isSitemap = file?.collection === 'sitemaps'
    const isUserFlow = file?.collection === 'user-flows'
    return (
        <MenuItem
            py={PY}
            isDisabled={isCopying}
            icon={isCopying ? <MenuItemSpinner /> : <CopyIcon fontSize={ICON_SIZE} />}
            onClick={async () => {
                // can only have one file in personal account
                if (isUserFile) return dispatch(toggleUpgradeModal({ showing: true, planId: 'pro', screen: `new-file_${file?.collection}` }))
                // continue
                setIsCopying.toggle()
                let newDocId = ''
                // is sitemap
                if (isSitemap) newDocId = await duplicateSitemap({ file, dispatch })
                // is user flow
                if (isUserFlow) newDocId = await duplicateUserFlow({ file, dispatch })
                // in editor (refresh)
                if (inEditor) {
                    if (newDocId) {
                        window.location = `/${getEditorSubfolderFromDoc({ id: newDocId, collection: file?.collection })}/${newDocId}`
                    }
                }
                // finish
                setIsCopying.toggle()
                onClose()
            }}>
            Make a copy
        </MenuItem>
    )
}

const duplicateSitemap = async ({ file, dispatch }) => {

    const firebase = getFirebase()

    const res = await firebase.functions().httpsCallable('sitemaps-duplicateSitemap')({ sitemapId: file?.id })
    // retrieved
    const newSitemapId = res?.data?.newSitemapId
    if (!newSitemapId) return;

    const newDocAttributes = getNewDocAttributes(file)
    // dispatch changes (HAVE to omit old id so this will work)
    dispatch(mergeFileChange(newSitemapId, file?.collection, { ...omit(file, ['id']), ...newDocAttributes }))

    // return
    return newSitemapId

}

const duplicateUserFlow = async ({ file, dispatch }) => {

    const firestore = getFirestore()

    const userId = getUserId()

    const newDocId = getNewFirestoreDocId(file?.collection)

    try {

        // get old doc (don't use file as we add extra attrs)
        const copiedRef = doc(firestore, "user-flows", file?.id)
        const copiedDoc = await getDoc(copiedRef);

        // get old elements doc
        const copiedElementsRef = doc(firestore, `user-flows/${file?.id}/data`, "elements")
        const copiedElementsDoc = await getDoc(copiedElementsRef);
        const copiedElementsData = copiedElementsDoc.data() ? copiedElementsDoc.data()?.elements : (copiedDoc?.data()?.data || {})

        // new doc attributes
        const newDocAttributes = getNewDocAttributes(file)
        const newDocData = omit({ ...copiedDoc.data() || {}, ...newDocAttributes }, ['lastEdit', 'data', 'collaborators'])
        const newElementsDocAttributes = { updatedAt: new Date(), lastEdit: userId, elements: copiedElementsData }

        // save new docs
        const batch = writeBatch(firestore);

        // doc
        const newDocRef = doc(firestore, file?.collection, newDocId)
        batch.set(newDocRef, newDocData, { merge: true })

        // elements doc
        const newElementsRef = doc(firestore, `${file?.collection}/${newDocId}/data`, "elements")
        batch.set(newElementsRef, newElementsDocAttributes, { merge: true })

        // commit batch
        await batch.commit()

        // dispatch changes (HAVE to omit old id so this will work)
        dispatch(mergeFileChange(newDocId, file?.collection, { ...omit(file, ['id']), ...newDocAttributes }))

        return newDocId

    } catch (e) {
        console.error(e)
    }
}

const getNewDocAttributes = (file) => {
    const userId = getUserId()
    return { name: `${file?.name} (Copy)`, archived: false, createdAt: new Date(), updatedAt: new Date(), createdBy: userId, updatedBy: userId }
}
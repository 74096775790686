import { Alert, Avatar, Badge, Box, Button, HStack, IconButton, ListItem, Stack, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tooltip, Tr, UnorderedList, useColorModeValue as mode, Image } from "@chakra-ui/react"
import { MainHeader } from "../../components"
import { useGetOrganization, useGetOrganizationUsers, useGetTotalNumberOfSeats, useGetUserId, useInAgencyPlan, useInEnterprisePlan, useInProPlan, useIsOrganizationAdmin } from "../../../../hooks"
import { getEncodedEmail, getPhotoURL, getUsersFullName, toTitleCase } from "../../../../helpers"
import { Permissions } from "../../../Components/Shared/People/Permissions"
import { useDispatch } from "react-redux"
import { mergeOrganizationChange } from "../../../../store/actions/organization-actions"
import { AddIcon } from "@chakra-ui/icons"
import { toggleAddUsersModal, toggleUpgradeModal } from "../../../../store/actions/ui-actions"
import { getFirestore, setDoc, doc, deleteField } from "firebase/firestore"

import { useGetShortPlanId } from "../../../../../shared/hooks"

import { SkeletonCircleWrapper, SkeletonWrapper } from "../../../Components/Shared/SkeletonWrapper"
import { useIsTrialing } from "../../../../../shared/hooks/pricing"


export const People = () => {
    const dispatch = useDispatch()
    const isTrialing = useIsTrialing()
    const isAdmin = useIsOrganizationAdmin()
    const users = useGetOrganizationUsers()
    const numberOfSeats = useGetTotalNumberOfSeats()
    const inProPlan = useInProPlan()
    const inEnterprisePlan = useInEnterprisePlan()
    const inAgencyPlan = useInAgencyPlan()
    const shortPlanId = useGetShortPlanId()
    const numberOfTakenSeats = users?.length
    const seatsAvailable = numberOfSeats - numberOfTakenSeats
    const allSeatsTaken = numberOfTakenSeats >= numberOfSeats
    const inEnterpriseAndUnderSeatAllowance = inEnterprisePlan && !allSeatsTaken
    const inEnterpriseAndUsedAllSeats = inEnterprisePlan && allSeatsTaken
    return (
        <Stack maxW="full" spacing={5}>
            <Stack direction="row" align="center" justify="space-between">
                <MainHeader title={(
                    <Stack direction="row" align="center" spacing={3}>
                        <Text>People</Text>
                        <Badge
                            fontSize="13px"
                            px={3}
                            py={1}
                            color="fg.muted"
                        >
                            {`${users?.length} of ${numberOfSeats === Infinity ? 'Unlimited' : numberOfSeats} Seats`}
                        </Badge>
                    </Stack>
                )} />
            </Stack>
            <Alert rounded="md">
                <HStack w="full" justify="space-between" pl={2.5} pr={10}>
                    <Stack w="full" p={4} py={6} spacing={7}>
                        <Stack spacing={6}>
                            <MainHeader
                                fontSize="xl"
                                title={
                                    <>
                                        {inProPlan && "Get the team together with Rarchy Enterprise"}
                                        {(inAgencyPlan || inEnterpriseAndUnderSeatAllowance) && "Get even more of the team together"}
                                        {inEnterpriseAndUsedAllSeats && "Your team is looking strong"}
                                    </>}
                            />
                            <SkeletonWrapper w="fit-content">
                                {inProPlan && (
                                    <Box fontWeight="normal">
                                        Upgrade to Rarchy Enterprise and <strong>invite up to 4 additional users</strong> to your team.
                                    </Box>
                                )}
                                {inEnterpriseAndUnderSeatAllowance && (
                                    <Box fontWeight="normal">
                                        You still have <strong>{`${seatsAvailable} seats available`}</strong>  in your Rarchy {toTitleCase(`${shortPlanId}`)} account. Invite more people to:
                                    </Box>
                                )}
                                {inEnterpriseAndUsedAllSeats && (
                                    <Box fontWeight="normal">
                                        You still have <strong>{`${seatsAvailable} seats available`}</strong>  in your Rarchy {toTitleCase(`${shortPlanId}`)} account. Invite more people to:
                                    </Box>
                                )}
                                {inAgencyPlan && (
                                    <Box fontWeight="normal">
                                        You have <strong>unlimited seats</strong> in your Rarchy Agency account. Invite more people and take collaboration to the next level:
                                    </Box>
                                )}
                            </SkeletonWrapper>
                            <ListItems />
                        </Stack>
                        <SkeletonWrapper w="fit-content">
                            <Button
                                w="max-content"
                                fontSize="sm"
                                px={6}
                                colorScheme="teal"
                                isDisabled={!isAdmin}
                                onClick={() => {
                                    if (inProPlan) {
                                        dispatch(toggleUpgradeModal({ showing: true, planId: 'enterprise', lockPlan: true, screen: 'plans-pricing' }))
                                    } else {
                                        dispatch(toggleAddUsersModal())
                                    }
                                }}
                            >
                                {inProPlan ? `${!isTrialing ? "Upgrade to Rarchy Enterprise" : "Try Rarchy Enterprise for free"}` : "Invite people"}
                            </Button>
                        </SkeletonWrapper>
                    </Stack>
                    {(inEnterpriseAndUsedAllSeats || inAgencyPlan) && (
                        <SkeletonWrapper w="fit-content">
                            <Image w="300px" src="https://help.rarchy.com/hubfs/Invite%20People%20-%20Full%20Team.png" />
                        </SkeletonWrapper>
                    )}
                    {(inProPlan || inEnterpriseAndUnderSeatAllowance) && (
                        <SkeletonWrapper w="fit-content">
                            <Box
                                as='video'
                                w="300px"
                                autoPlay
                                priority="true"
                                preload="true"
                                muted
                                loop
                                controls={false}
                                src={mode("https://help.rarchy.com/hubfs/Invite%20People%20-%20Light.mp4", "https://help.rarchy.com/hubfs/Invite%20People%20-%20Dark.mp4")}
                            />
                        </SkeletonWrapper>
                    )}
                </HStack>
            </Alert>
            <Stack spacing={6} mt={10}>
                <PeopleList />
            </Stack>
        </Stack>
    )
}

const ListItems = () => {
    return (
        <UnorderedList spacing={2.5}>
            <SkeletonWrapper w="fit-content">
                <ListItem key={3} fontWeight="normal">
                    <strong>Stay aligned and avoid duplicating work</strong> by bringing everyone into one account
                </ListItem>
            </SkeletonWrapper>
            <SkeletonWrapper w="fit-content">
                <ListItem key={4} fontWeight="normal">
                    <strong>Keep projects private when needed</strong> with folder permissions
                </ListItem>
            </SkeletonWrapper>
        </UnorderedList>
    )
}

export const PeopleList = () => {
    const dispatch = useDispatch()
    const organization = useGetOrganization()
    const users = useGetOrganizationUsers()
    const idOfUserMakingChange = useGetUserId()
    return (
        <Stack>
            <TableContainer>
                <Table size='sm' display="table">
                    <Thead>
                        <Tr>
                            <Th>
                                <SkeletonWrapper w="fit-content">
                                    Name
                                </SkeletonWrapper>
                            </Th>
                            <Th>
                                <SkeletonWrapper w="fit-content">
                                    Email
                                </SkeletonWrapper>
                            </Th>
                            <Th>
                                <SkeletonWrapper w="fit-content">
                                    Team role
                                </SkeletonWrapper>
                            </Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {users?.map(user => {
                            const name = getUsersFullName(user)
                            const photoURL = getPhotoURL(user)
                            return (
                                <Tr key={user?.id} h="64px">
                                    <Td>
                                        <Stack direction="row" spacing={5} align="center">
                                            <SkeletonCircleWrapper>
                                                <Avatar size="smd" name={name} src={photoURL} />
                                            </SkeletonCircleWrapper>
                                            <SkeletonWrapper w="fit-content">
                                                <Text>{name}</Text>
                                            </SkeletonWrapper>
                                        </Stack>
                                    </Td>
                                    <Td>
                                        <SkeletonWrapper w="fit-content">
                                            {user?.email}
                                        </SkeletonWrapper>
                                    </Td>
                                    <Td>
                                        <SkeletonWrapper w="fit-content">
                                            <Permissions
                                                user={user}
                                                forOrganization
                                                onChange={async (role) => {
                                                    const encodedId = getEncodedEmail(user?.id)
                                                    const data = (forFirestore) => { return { [encodedId]: role ? { role } : forFirestore ? deleteField() : undefined } }
                                                    // dispatch to redux
                                                    dispatch(mergeOrganizationChange({ users: data() }))
                                                    // update in firestore
                                                    try {
                                                        const ref = doc(getFirestore(), 'organizations', organization?.id);
                                                        await setDoc(ref, { users: data(true), updatedBy: idOfUserMakingChange, updatedAt: new Date() }, { merge: true });
                                                    } catch (e) {
                                                        console.error(e)
                                                    }
                                                }}
                                            />
                                        </SkeletonWrapper>
                                    </Td>
                                </Tr>
                            )
                        })}
                    </Tbody>
                </Table>
            </TableContainer>
            <Stack
                mt={2.5}
                px={4}
                direction="row"
                spacing={4}
                align="center"
                onClick={() => {
                    dispatch(toggleAddUsersModal())
                }}
            >
                <Tooltip variant="rounded-sm" label="Invite people" placement="bottom-start" openDelay={500} hasArrow>
                    <SkeletonCircleWrapper>
                        <IconButton isRound icon={<AddIcon />} />
                    </SkeletonCircleWrapper>
                </Tooltip>
                <SkeletonWrapper w="fit-content">
                    <Button _hover={{ bg: "transparent" }} variant="ghost" size="sm">Invite people</Button>
                </SkeletonWrapper>
            </Stack>
        </Stack>
    )
}
import { Button, Stack, Text, useBoolean } from "@chakra-ui/react"
import { useGetSubscription, useInProPlan } from "../../../hooks"
import { useIsBilledYearly } from "../../../../shared/hooks/pricing"
import { usePricingContext } from "../../../../shared/hooks/"
import { useFirebase } from "react-redux-firebase"
import { wait } from "../../../../shared/helpers"
import { ArrowForwardIcon } from "@chakra-ui/icons"

export const UpgradeToEnterpriseInStripe = ({ buttonText, add_ons }) => {

    const [isLoading, setIsLoading] = useBoolean()

    const firebase = useFirebase()

    const inProPlan = useInProPlan()
    const { pricing } = usePricingContext()
    const isBilledYearly = useIsBilledYearly()
    const subscription = useGetSubscription()

    if (!inProPlan) return null; // fail-safe

    const { seats } = add_ons || {}

    const { customer_id, id: subscription_id } = subscription
    const enterprisePriceId = `${pricing?.plans?.enterprise?.id}${isBilledYearly ? 0 : ''}`

    const items = [
        {
            id: subscription?.plan?.subscription_id,
            price: enterprisePriceId,
            quantity: 1
        }
    ]

    // additional seats
    if (seats) {
        items.push({
            price: pricing?.add_ons?.seats?.id,
            quantity: seats
        })
    }

    return (
        <Stack w="full" spacing={4}>
            <Button
                w="full"
                fontSize={buttonText || "sm"}
                colorScheme="blue"
                isLoading={isLoading}
                rightIcon={<ArrowForwardIcon />}
                onClick={async () => {
                    setIsLoading.toggle()
                    const sessionURL = await firebase.functions().httpsCallable('stripe-createCustomerPortalSession')(
                        {
                            return_url: window.location.href,
                            customerId: customer_id,
                            flow_data: {
                                type: "subscription_update_confirm",
                                subscription_update_confirm: {
                                    subscription: subscription_id,
                                    items
                                }
                            }
                        })
                    if (sessionURL?.data) window.location = sessionURL.data
                    await wait(1000)
                    setIsLoading.toggle()
                }}
            >
                {buttonText || `Upgrade to Rarchy Enterprise`}
            </Button>
            <Text fontSize="xs" color="fg.muted">
                {`After clicking continue, we'll take you to our billing provider (Stripe), to confirm your upgrade to Rarchy Enterprise.`}
            </Text>
        </Stack>
    )
}
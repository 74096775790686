import { useDispatch } from "react-redux"
import { SplitModal } from "../../Components/Modals/SplitModal"
import { toggleNewSitemapModal, toggleUpgradeModal } from "../../../store/actions/ui-actions"
import { Box, Button, Heading, Image, Stack, Text, useColorModeValue as mode, useBoolean, useMediaQuery } from "@chakra-ui/react"
import { useGetFolderIdFromPath, useGetNewSitemapModal, useGetUserId, useHitSitemapsLimit } from "../../../hooks"
import { SitemapImportDrawer } from "./Import"
import { MidHeader } from "../../Settings/Components"
import { Templates } from "./Templates"
import DefaultBg from '../../UpgradeAccount/Images/Default/Background.jpg'
import { wait } from "../../../../shared/helpers"
// import amplitude from 'amplitude-js/amplitude';
import { createNodeId } from "../../Sitemap/utils/app"
import { getFirestore, doc, writeBatch } from "firebase/firestore"
import { useHistory } from "react-router-dom"
import { mergeFileChange } from "../../../store/actions/files-actions"
import { getNewFirestoreDocId, sendHubspotCustomEvent } from "../../../helpers"

export const NewSitemapModalContainer = () => {
    const dispatch = useDispatch()
    const [hideRight] = useMediaQuery('(max-width: 1100px)')
    const [showTemplatesScreen, setShowTemplatesScreen] = useBoolean(false)
    const NewSitemapModal = useGetNewSitemapModal()
    const { createType } = NewSitemapModal || {}
    const isNew = createType === 'new'
    if (!isNew) return <SitemapImportDrawer />
    return (
        <SplitModal
            isOpen
            onClose={() => dispatch(toggleNewSitemapModal())}
            isFull={!isNew || showTemplatesScreen}
            size="5xl"
            left={
                <>
                    {!showTemplatesScreen && <Left setShowTemplatesScreen={setShowTemplatesScreen} />}
                    {showTemplatesScreen && <Templates inNewSitemapModal w={hideRight ? "sm" : "5xl"} setShowTemplatesScreen={setShowTemplatesScreen} />}
                </>
            }
            right={<Right />}
        />
    )

}

const Left = ({ setShowTemplatesScreen }) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const hitSitemapsLimit = useHitSitemapsLimit()
    const [isCreating, setIsCreating] = useBoolean(false)
    const userId = useGetUserId()
    const folderId = useGetFolderIdFromPath({ returnAccountId: true })
    return (
        <Stack w="md" p={9} py={20} mb={5} spacing={10}>
            <Heading fontSize="2xl" fontWeight="semibold">
                How would you like to get started with your new sitemap?
            </Heading>
            <Stack spacing={3.5}>
                <MidHeader title="Choose a template" />
                <Text fontSize="sm">Choose a template to give you a head start, then modify however you like.</Text>
                <Button w="full" colorScheme="blue" fontSize="sm" onClick={setShowTemplatesScreen.toggle}>Browse templates</Button>
            </Stack>
            <Stack spacing={3.5}>
                <MidHeader title="Start from scratch" />
                <Text fontSize="sm">Create a blank canvas and build your sitemap from scratch.</Text>
                <Button
                    w="full"
                    colorScheme="blue"
                    fontSize="sm"
                    isLoading={isCreating}
                    onClick={async () => {

                        setIsCreating.on()
                        
                        // have hit sitemaps limit
                        if (hitSitemapsLimit) {
                            await wait(1000)
                            dispatch(toggleNewSitemapModal({ showing: false }))
                            dispatch(toggleUpgradeModal({ showing: true, screen: `new-file_sitemaps`, planId: 'pro' }))
                            return;
                        }

                        try {

                            // create new sitemap

                            const firestore = getFirestore()

                            // Get a new write batch
                            const batch = writeBatch(firestore);

                            const newDocData = {
                                archived: false,
                                createdAt: new Date(),
                                createdBy: userId,
                                updatedAt: new Date(),
                                updatedBy: userId,
                                name: 'Untitled Sitemap',
                                team: folderId
                            };

                            const docId = getNewFirestoreDocId("sitemaps");

                            // set doc
                            const docRef = doc(firestore, "sitemaps", docId);
                            batch.set(docRef, newDocData, { merge: true });

                            // set pages
                            const pagesRef = doc(docRef, "data", "pages")
                            batch.set(pagesRef, { pages, lastEdit: userId, updatedAt: new Date() }, { merge: true })
                            // set covers
                            const coversRef = doc(docRef, "data", "covers")
                            batch.set(coversRef, { pages: {}, lastEdit: userId, updatedAt: new Date() }, { merge: true })
                            // set sections
                            const sectionsRef = doc(docRef, "data", "sections")
                            batch.set(sectionsRef, { pages: {}, lastEdit: userId, updatedAt: new Date() }, { merge: true })
                            // set comments
                            const commentsRef = doc(docRef, "data", "comments")
                            batch.set(commentsRef, { pages: {}, lastEdit: userId, updatedAt: new Date() }, { merge: true })
                            // set website_sections
                            const websiteSectionsRef = doc(docRef, "data", "website_sections")
                            batch.set(websiteSectionsRef, { data: { [createNodeId()]: { index: 0, title: 'Footer' } }, lastEdit: userId, updatedAt: new Date() }, { merge: true })

                            // commit batch to firestore
                            await batch.commit()

                            // amplitude tracking
                            // amplitude.getInstance().logEvent('CREATED_NEW_SITEMAP', { id: docId });
                            // push new created sitemap event to GTM
                            window.dataLayer.push({ 'event': 'created_sitemap', 'sitemap_type': 'new' });

                            await wait(1000)

                            // insert new file into files
                            dispatch(mergeFileChange(docId, 'sitemaps', { id: docId, collection: 'sitemaps', ...newDocData }))

                            history.push(`/app/sitemap/${docId}`)

                            dispatch(toggleNewSitemapModal({ showing: false }))

                            // send event to Hubspot
                            sendHubspotCustomEvent('created_sitemap', { sitemap_type: 'build' })

                        } catch (e) {
                            console.error(e)
                        } finally {
                            setIsCreating.off()
                        }
                    }}
                >
                    Start from scratch
                </Button>
            </Stack>
        </Stack>
    )
}

const pages = {
    home: {
        name: 'Home',
        parent: null,
    },
    gukpmzfaab: {
        name: 'Page',
        parent: 'home',
        index: 0,
    },
    gukpmzfbab: {
        name: 'Page',
        parent: 'home',
        index: 1,
    },
    gukpmzfcab: {
        name: 'Page',
        parent: 'home',
        index: 2,
    },
};

const LightImage = "https://help.rarchy.com/hubfs/Transparent%20-%20Light-1080.png"
const DarkImage = "https://help.rarchy.com/hubfs/Transparent%20-%20Dark-1080.png"

const Right = () => {
    return (
        <Box w="full" bgImage={DefaultBg}>
            <Box p={16} mt={5}>
                <Image
                    src={mode(LightImage, DarkImage)}
                    w="full"
                />
            </Box>
        </Box>
    )
}
import { Alert, AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, AlertIcon, Box, Button, ListItem, UnorderedList, VStack, useBoolean, useDisclosure } from "@chakra-ui/react"
import { useGetScreenshotsLimitByPlan, useIsTrialing } from "../../../../../../../shared/hooks/pricing"
import { useGetSubscription, useGetUser, useInOrganization, useInProPlan, useIsOrganizationAdmin } from "../../../../../../hooks"

import dayjs from 'dayjs'
import { getFirebase } from 'react-redux-firebase';
import relativeTime from 'dayjs/plugin/relativeTime'
import { toggleUpgradeModal } from "../../../../../../store/actions/ui-actions"
import { useDispatch } from "react-redux"
import { useGetMonthlyScreenshotsRemaining } from "../hooks"
import { wait } from "../../../../../../../shared/helpers"

dayjs.extend(relativeTime)

export const HitLimitAlert = ({ checked }) => {

    const dispatch = useDispatch()

    const isAdmin = useIsOrganizationAdmin()

    const isTrialing = useIsTrialing()
    const inProPlan = useInProPlan()

    const screenshotsRemaining = useGetMonthlyScreenshotsRemaining()
    const monthlyScreenshots = useGetScreenshotsLimitByPlan()

    const user = useGetUser()
    const inOrganization = useInOrganization()
    const subscription = useGetSubscription()

    const numberOfPagesToDeselect = Math.abs(screenshotsRemaining - checked?.length)

    const { current_period_end } = subscription

    const isTrialingAndCapped = isTrialing
    const showUpgrade = inProPlan && !isTrialing

    const refresh_date = inOrganization ? dayjs.unix(subscription?.current_period_end) : dayjs(user?.limits?.screenshots?.refresh_at?.toDate()?.getTime())

    return (
        <Alert status='info' px={4} py={4} mt={0} mb={4} variant="info" alignItems="flex-start" fontWeight="normal">
            <AlertIcon color="gray.500" />
            <VStack align="start" ml={1}>
                {!showUpgrade && !isTrialingAndCapped && (
                    /* user isn't trialing, and isn't in Pro plan */
                    <Box>
                        <strong>De-select {numberOfPagesToDeselect?.toLocaleString()} pages</strong>
                        {` to continue capturing your screenshots`}`
                        {inOrganization && (
                            <>
                                {'or '}
                                <Button
                                    size="sm"
                                    variant="link"
                                    color="rarchy-link"
                                    fontWeight="medium"
                                    mr={1.5}
                                    onClick={() => {
                                        dispatch(toggleUpgradeModal({ showing: true, planId: 'pro', screen: 'screenshots' }))
                                    }}
                                >
                                upgrade to a paid plan
                                </Button>
                                {`to increase your screenshot limits`}.
                            </>
                        )
                        }
                    </Box>
                )}
                {showUpgrade && (
                    /* user is in Pro plan, but isn't trialing */
                    <Box>
                        <Button
                            isDisabled={!isAdmin}
                            size="sm"
                            variant="link"
                            color="rarchy-link"
                            fontWeight="medium"
                            textDecor="underline"
                            textUnderlineOffset={4}

                            onClick={() => { dispatch(toggleUpgradeModal({ showing: true, planId: 'enterprise', screen: 'plans-pricing' })) }}
                        >
                            Upgrade to Rarchy Enterprise
                        </Button>
                        {` to capture up to ${5000?.toLocaleString()} screenshots every month, or:`}
                        <UnorderedList spacing={1} mt={2}>
                            <ListItem>
                                {`Wait until your monthly limits of ${monthlyScreenshots?.toLocaleString()} screenshots refreshes on ${dayjs.unix(current_period_end).format('ll')}.`}
                            </ListItem>
                            <ListItem>
                                {`De-select ${numberOfPagesToDeselect} pages to continue capturing.`}
                            </ListItem>
                        </UnorderedList>
                    </Box>
                )}
                {isTrialingAndCapped && (
                    /* user is trialing, and is in the Enterprise plan (capped screenshots) */
                    <>
                        <Box>
                            We limit free trials to 100 screenshot captures, to prevent mis-use.
                        </Box>
                        <Box>
                            <ConfirmAlert />
                            {` to unlock your full monthly allowance of ${monthlyScreenshots?.toLocaleString()} screenshots immediately, or:`}
                            <UnorderedList spacing={1} mt={2}>
                                <ListItem>
                                    {`Wait until your free trial ends on ${dayjs.unix(current_period_end).format('ll')}.`}
                                </ListItem>
                                <ListItem>
                                    {`Deselect ${numberOfPagesToDeselect} pages to continue capturing.`}
                                </ListItem>
                            </UnorderedList>
                        </Box>
                    </>
                )}
            </VStack>
        </Alert>
    )
}

function ConfirmAlert() {

    const { isOpen, onOpen, onClose } = useDisclosure()
    const [isEnding, setIsEnding] = useBoolean()

    const isAdmin = useIsOrganizationAdmin()
    const subscription = useGetSubscription()

    return (
        <>
            <Button
                isDisabled={!isAdmin}
                size="sm"
                variant="link"
                color="rarchy-link"
                fontWeight="medium"
                textDecor="underline"
                textUnderlineOffset={4}
                onClick={onOpen}
            >
                End your trial early
            </Button>

            <AlertDialog
                isOpen={isOpen}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent pb={2}>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            End your trial early
                        </AlertDialogHeader>

                        <AlertDialogBody fontSize="15px">
                            Your free trial will end, and your provided payment method will be charged immediately.
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button onClick={onClose} fontSize="sm" variant="ghost">
                                Cancel
                            </Button>
                            <Button
                                colorScheme='blue'
                                fontSize="sm"
                                ml={3}
                                isLoading={isEnding}
                                onClick={async () => {
                                    setIsEnding.toggle();
                                    const firebase = getFirebase();
                                    await firebase.functions().httpsCallable('stripe-endTrialEarly')({ subscriptionId: subscription.id });
                                    await wait(2500);
                                    setIsEnding.toggle();
                                    onClose();
                                }}
                            >
                                Yes, continue
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
}
import { useDispatch } from "react-redux";
import { useFirebase } from "react-redux-firebase";
import { Box, Flex, Stack } from "@chakra-ui/react";
import { Close, Container } from "../Components";
import { useGetFolderIdFromPath, useGetImportingSitemap, useGetSitemapImportHistory, useHitSitemapsLimit, useInOrganization, useIsImportingSitemap, usePrevious } from "../../../../../hooks";
import { Topbar } from "./Topbar";
import { Pages } from "./Pages";

import { createSitemapImportOptions, tabs } from "..";
import { ImportStatus } from "../../../Import/Shared/Status";

import { useEffect, useState } from "react";
import { clearProcessedSitemap, getImportPagesDataFromStorage, getImportedSitemapPages } from "../../../../../store/actions/sitemaps-actions";
import { Formik } from "formik";

import { useIsSitemapImportCrawl } from "../helpers";
import { useHistory } from "react-router-dom";
import { toggleNewSitemapModal, toggleUpgradeModal } from "../../../../../store/actions/ui-actions";

import { mergeFileChange } from "../../../../../store/actions/files-actions";

import { wait } from "../../../../../../shared/helpers";

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import localizedFormat from 'dayjs/plugin/localizedFormat';

import { UpgradeBanner } from "./UpgradeBanner/"

import { isEmpty, compact, uniqBy, concat } from 'lodash'

dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);
dayjs.extend(updateLocale);

export const DETAIL_W = "5xl"

export const ImportDetail = ({ detailId }) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const firebase = useFirebase()

    const [isCreating, setIsCreating] = useState(false)

    const [pages, setPages] = useState([])

    // importing
    const importing = useGetImportingSitemap()
    // imported
    const imported = useGetSitemapImportHistory()?.byId?.[detailId]
    // data to use
    const data = { ...(importing || imported) }
    // add in latest importing pages update -
    data.pages = { ...data?.pages, ...pages, data: compact(uniqBy(concat(pages?.data, data?.pages?.data), 'url')) }
    // is crawl
    const isCrawl = useIsSitemapImportCrawl(data)
    // set imported pages
    SetImportedPages({ imported, detailId })
    // update importing pages
    UpdateImportingPages({ importing, imported, setPages })
    // clear processed import on mount/unmount
    ClearProcessedSitemapOnMount({ importing })
    ClearProcessedSitemapOnUnmount({ importing })
    // have hit sitemaps limit (in free account)
    const hitSitemapsLimit = useHitSitemapsLimit()
    // folder id
    const folderId = useGetFolderIdFromPath({ returnAccountId: true })
    // render
    return (
        <>
            <Formik
                initialValues={data?.options}
                onSubmit={async (values) => {
                    try {
                        setIsCreating(true)
                        // have hit sitemaps limit
                        if (hitSitemapsLimit) {
                            await wait(1000)
                            return dispatch(toggleUpgradeModal({ showing: true, screen: `new-file_sitemaps`, planId: 'pro' }))
                        }
                        // continue to create
                        const { submitVariant } = values;
                        const options = createSitemapImportOptions({ isCrawl, values });
                        if (submitVariant === 'create') {
                            const response = await firebase.functions().httpsCallable('sitemaps-import-createSitemap')({ id: detailId, options, folderId });
                            // insert new file into files
                            dispatch(mergeFileChange(detailId, 'sitemaps', { id: detailId, collection: 'sitemaps', ...response.data?.sitemap }))
                            // close modal
                            setIsCreating(false)
                            dispatch(toggleNewSitemapModal({ showing: false }))
                            // go to sitemap
                            history.push(`/app/sitemap/${response?.data?.sitemap?.id}`)
                        }
                        if (submitVariant === "resume") {
                            // alert("resume!")
                        }
                    } catch (e) {
                        console.error(e)
                    } finally {
                        setIsCreating(false)
                    }
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                }) => {
                    if (!isEmpty(errors)) console.log(errors);
                    return (
                        <>
                            <Container>
                                <Close />
                                <ImportStatus />
                                <UpgradeBanner data={data} />
                            </Container>
                            <form>
                                <Stack w="full" justify="center" h="full" px={20}>
                                    <Flex w="full" justify="center" h="full">
                                        <Box w={DETAIL_W}>
                                            <Topbar data={data} options={values} handleSubmit={handleSubmit} isCreating={isCreating} /* options={options} setOptions={setOptions} */ />
                                        </Box>
                                    </Flex>
                                    <Flex w="full" justify="center" h="full">
                                        <Pages data={data} options={values} />
                                    </Flex>
                                </Stack>
                            </form>
                        </>)
                }}
            </Formik>
        </>
    )
}

const ClearProcessedSitemapOnMount = ({ importing }) => {
    const dispatch = useDispatch()
    useEffect(() => {
        if (importing?.processed) dispatch(clearProcessedSitemap(importing))
    }, []);
}

const ClearProcessedSitemapOnUnmount = ({ importing }) => {
    const dispatch = useDispatch()
    useEffect(() => {
        return () => {
            if (importing?.processed) dispatch(clearProcessedSitemap(importing))
        }
    }, []);
}

const UpdateImportingPages = ({ importing, imported, setPages }) => {
    const isImporting = useIsImportingSitemap()
    const prevImporting = usePrevious(importing)
    useEffect(() => {
        async function fetchData() {
            const count = importing?.retrieved?.count || 0;
            const prevCount = prevImporting?.retrieved?.count || 0;
            if (count > prevCount) {
                setPages(await getImportPagesDataFromStorage({ importing }))
            }
        }
        if (isImporting) fetchData()
    }, [importing, prevImporting, imported])
}

const SetImportedPages = ({ imported, detailId }) => {
    const dispatch = useDispatch()
    useEffect(() => {
        if (imported && !imported.pages) dispatch(getImportedSitemapPages({ detailId, isEmpty: imported?.retrieved?.count <= 1 }))
    }, [])
}

export const getTypeLabel = (type) => {
    return tabs?.find(t => t.key === type)?.label;
}
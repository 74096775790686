import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalCloseButton,
    ModalHeader,
    Stack,
    Box,
    IconButton,
    Heading,
    HStack,
    Icon
} from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import { useGetShareFileModal, useIsUserFile } from '../../../../hooks'
import { toggleShareFileModal } from '../../../../store/actions/files-actions'
import { People } from '../../../Components/Shared/People'
import { General } from './General'
import { Invite } from '../../../Components/Shared/People/Invite'
import { ShareFileHOC } from './ShareFileHOC'
import { ArrowBackIcon } from '@chakra-ui/icons'
import { MdTipsAndUpdates } from 'react-icons/md'
import { StartTrialUpgradeButton } from '../../../UpgradeAccount/Components/StartTrialUpgradeButton'
import { TRIAL_DAYS, useCanStartNewTrial } from '../../../../../shared/hooks/pricing'
import { isEmpty } from 'lodash'

export const ShareFileModal = () => {
    const dispatch = useDispatch()
    const fileId = useGetShareFileModal()?.file?.id
    const isUserFile = useIsUserFile({ id: fileId })
    const canStartNewTrial = useCanStartNewTrial()
    const onClose = () => dispatch(toggleShareFileModal())
    return (
        <ShareFileHOC fileId={fileId} onClose={onClose}>
            {({ file, setInputValue, peopleToInvite, setPeopleToInvite }) => {
                const hasPeopleToInvite = !isEmpty(peopleToInvite)
                return (

                    <Modal isOpen onClose={onClose} size="xl" isCentered>
                        <ModalOverlay />
                        <ModalContent>
                            <ModalCloseButton />
                            <ModalHeader px={7}>
                                <Stack direction="row" spacing={3}>
                                    {hasPeopleToInvite && (
                                        <IconButton
                                            variant="ghost"
                                            size="sm"
                                            icon={<ArrowBackIcon fontSize="xl" />}
                                            onClick={() => {
                                                setInputValue('')
                                                setPeopleToInvite([])
                                            }}
                                        />
                                    )}
                                    <Box>{`Share "${file?.name}"`}</Box>
                                </Stack>
                            </ModalHeader>
                            <ModalBody p={0}>
                                <Stack spacing={4}>
                                    {isUserFile && <Box mt={3}><General /></Box>}
                                    {!isUserFile && <Invite />}
                                    <People />
                                    {isUserFile && (
                                        <Box px={6} mb={9}>
                                            <Box
                                                border="1px solid"
                                                borderColor="rarchy-border"
                                                p={3}
                                                bg="rarchy-bg-subtle"
                                                rounded='md'
                                            >
                                                <Stack px={8} spacing={4} my={3}>
                                                    <Heading fontSize="md" fontWeight="semibold">Share with comment/edit access in Rarchy Pro</Heading>
                                                    <HStack fontSize="sm" spacing={3}>
                                                        <Icon as={MdTipsAndUpdates} fontSize="2xl" color="#ECC94B" />
                                                        <Box>{`To allow others to comment on and edit your sitemaps, ${canStartNewTrial ? `start a free ${TRIAL_DAYS}-day trial of` : 'upgrade to'} Rarchy Pro.`}
                                                        </Box>
                                                    </HStack>
                                                    <Box mt={-3}>
                                                        <StartTrialUpgradeButton hideCancelMessaging />
                                                    </Box>
                                                </Stack>
                                            </Box>
                                        </Box>
                                    )}
                                </Stack>
                                {!isUserFile && <Box mt={7} mb={9}><General /></Box>}
                            </ModalBody>
                        </ModalContent>
                    </Modal>
                )
            }}
        </ShareFileHOC>
    )
}
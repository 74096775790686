import React from 'react';
import {
    Box,
    Button,
    PopoverTrigger,
    IconButton,
    useColorModeValue as mode,
} from "@chakra-ui/react"
import { ChevronDownIcon } from '@chakra-ui/icons';
import { VscCollapseAll } from 'react-icons/vsc';
import { AddIcon } from '@chakra-ui/icons'

export const TriggerButton = ({ location }) => {
    return (
        <PopoverTrigger>
            {location === "toolbar" ? (
                <Box cursor="pointer" bgColor="rarchy-bg-sitemap-editor" rounded="md">
                    <Button
                        variant="outline"
                        size="sm"
                        iconSpacing={3}
                        lineHeight="inherit"
                        rightIcon={<ChevronDownIcon fontSize="xl" ml={-1} />}
                        leftIcon={<VscCollapseAll fontSize="15px" style={{ transform: "rotate(180deg) scaleX(-1)" }} />}
                        color="rarchy-color-sitemap-editor"
                        borderColor="rarchy-border-sitemap-editor"
                        // borderWidth={0}
                        bgColor="rarchy-bg-sitemap-editor"
                    >
                        Subfolders
                    </Button>
                </Box>
            ) : <IconButton variant="ghost" size="xs" icon={<AddIcon color={mode("gray.500", "whiteAlpha.700")} />} />}
        </PopoverTrigger>
    )
}
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Box,
    Button,
    HStack,
    Icon,
} from '@chakra-ui/react'
import React, { useRef, useState } from 'react'
import { deleteFile, deleteUserFile, mergeFileChange } from '../../../store/actions/files-actions'
import { useGetUserId, useInOrganization } from '../../../hooks'

import { MdTipsAndUpdates } from 'react-icons/md'
import { getCollectionLabelFromDoc } from '../../../helpers'
import { toggleUpgradeModal } from '../../../store/actions/ui-actions'
import { useCanStartNewTrial } from '../../../../shared/hooks/pricing'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { wait } from '../../../../shared/helpers'

export const DeleteFileAlertDialog = ({ file, setShowDeleteAlert, onClose }) => {

    const dispatch = useDispatch()
    const history = useHistory()

    const cancelRef = useRef()

    const canStartNewTrial = useCanStartNewTrial()

    const inOrganization = useInOrganization()
    const [isDeleting, setIsDeleting] = useState(false)
    const userId = useGetUserId()
    const handleClose = () => setShowDeleteAlert(false)
    const collectionLabel = getCollectionLabelFromDoc(file)
    // return
    return (
        <AlertDialog
            isOpen={true}
            leastDestructiveRef={cancelRef}
            onClose={handleClose}
        >
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontWeight='semibold' color="rarchy-red" textTransform="capitalize">
                        {`Delete ${collectionLabel}`}
                    </AlertDialogHeader>
                    <AlertDialogBody>
                        {`Are you sure? This ${collectionLabel} will be permanently deleted, and can't be restored.`}
                        {!inOrganization && (
                            <HStack
                                mt={5}
                                spacing={5}
                                
                            >
                                <Icon as={MdTipsAndUpdates} fontSize="2xl" color="#ECC94B" />
                                <Box
                                    color="fg.muted"
                                    fontSize="sm">
                                    {`Restore ${collectionLabel}s up to 30 days after deletion, with Rarchy Pro. `}
                                    <Button
                                        variant="link"
                                        fontSize="sm"
                                        fontWeight="medium"
                                        color="rarchy-link"
                                        onClick={() => {
                                            handleClose()
                                            dispatch(toggleUpgradeModal({ showing: true, planId: 'pro' }))
                                        }}
                                    >
                                        {canStartNewTrial ? `Start a free trial today.` : `Upgrade your plan today.`}
                                    </Button>
                                </Box>
                            </HStack>
                        )}
                    </AlertDialogBody>
                    <AlertDialogFooter pb={6}>
                        <Button ref={cancelRef} isDisabled={isDeleting} fontSize="sm" onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button
                            colorScheme='red'
                            fontSize="sm"
                            ml={3}
                            isLoading={isDeleting}
                            onClick={async () => {
                                setIsDeleting(true)
                                const fileId = file?.id
                                const isUserFile = fileId === userId
                                await wait(1000)
                                dispatch(isUserFile ? deleteUserFile({ collection: file?.collection, history }) : deleteFile({ file }))
                                dispatch(mergeFileChange(fileId, file?.collection, undefined))
                                onClose()
                            }}
                        >
                            Delete
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    )
}
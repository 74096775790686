import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    Tooltip,
    Link,
    useColorModeValue as mode
} from "@chakra-ui/react"

import { RepeatClockIcon } from '@chakra-ui/icons'
import { toggleRevisionHistoryDrawer } from '../../../../../../../store/actions/editor-actions'
import dayjs from 'dayjs';
import { useGetSitemap, useGetUserFlow, useInUserFlow, useIsUserFile } from '../../../../../../../hooks'
import { toggleUpgradeModal } from '../../../../../../../store/actions/ui-actions'

export const RevisionHistory = ({ canEdit }) => {
    const dispatch = useDispatch()
    const sitemap = useGetSitemap()
    const flow = useGetUserFlow()
    const inUserFlow = useInUserFlow()
    const inUserFile = useIsUserFile()
    return (
        <>
            <Tooltip variant="rounded-sm" hasArrow label="Open Revision History" openDelay={1000}>
                <Link
                    onClick={() => {
                        if (inUserFile) {
                            dispatch(toggleUpgradeModal({ showing: true, planId: 'pro', screen: 'revision-history' }))
                        } else {
                            if (canEdit) dispatch(toggleRevisionHistoryDrawer())
                        }
                    }}
                    display="flex"
                    alignItems="center"
                >
                    <RepeatClockIcon mr={2} mt="1px" fontSize="xs" />
                    {dayjs(!inUserFlow ? sitemap?.updatedAt : flow?.updatedAt).format('lll')}
                </Link>
            </Tooltip>
        </>
    )
}
import {
    Box,
    Button,
    IconButton,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Stack,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue as mode,
} from "@chakra-ui/react"
import { TRIAL_DAYS, useCanStartNewTrial } from '../../../../../../shared/hooks/pricing'
import { cloneDeep, isEmpty } from 'lodash'
import { useFirebase, useFirestore } from 'react-redux-firebase'
import { useGetSitemap, useGetUser, useInUserEditorDoc } from '../../../../../hooks'

import { AiOutlineMore } from 'react-icons/ai'
import React from 'react'
import dayjs from 'dayjs'
import fileDownload from 'js-file-download'
import filesize from 'filesize'
import { mergePagesEdits } from '../../../../../store/actions/sitemap-actions'
import { toggleUpgradeModal } from '../../../../../store/actions/ui-actions'
import { useDispatch } from 'react-redux'

export const FilesTable = ({ files, filteredFiles, setFiles }) => {

    const firestore = useFirestore()
    const firebase = useFirebase()

    const dispatch = useDispatch()

    const sitemap = useGetSitemap()
    const inUserDoc = useInUserEditorDoc()
    const canStartNewTrial = useCanStartNewTrial()

    const user = useGetUser()

    const { PageDrawer } = sitemap?.ui;

    var filesData = isEmpty(filteredFiles) ? files : filteredFiles;

    const loggedIn = user?.id;

    if (inUserDoc) return (
        <Stack w="full" fontSize="sm" mt={3} px={3} spacing={5}>
            <Box>
                File uploads are only available in our paid plans.
            </Box>
            <Box>
                <Button size="sm" variant="link" color="rarchy-link" fontWeight="medium" mr={1.5} onClick={() => {
                    dispatch(toggleUpgradeModal({ showing: true, planId: 'pro' }))
                }}>
                    {canStartNewTrial ? `Try Rarchy Pro for free for ${TRIAL_DAYS} days` : `Upgrade to Rarchy Pro`}
                    </Button>
            </Box>
        </Stack>
    )

    return (
        // table width = 624px
        <Table size="sm" my="8">
            <Thead w="full">
                <Tr>
                    <Th lineHeight={7} w="350px">
                        Name
                    </Th>
                    <Th lineHeight={7} w="100px" textAlign="center">
                        Size
                    </Th>
                    <Th lineHeight={7} w="120px">
                        Uploaded
                    </Th>
                    <Th lineHeight={7} w="44px" />
                </Tr>
            </Thead>
            <Tbody>
                {isEmpty(filesData) && (
                    <Tr key={0}>
                        <Text as={Td} w="full" fontSize="sm" px={3} py={6}>
                            {loggedIn ? `No files have been uploaded yet` : `Please login to view uploaded files`}
                        </Text>
                    </Tr>
                )}
                {filesData.map((file, index) => {
                    return (
                        <Tr key={index} color="fg.muted">
                            <Td key="name" fontSize="14px">
                                <Text noOfLines={1}>{file.name}</Text>
                            </Td>
                            <Td key="size" fontSize="13px" textAlign="center">
                                {filesize(file.size, { round: 0 })}
                            </Td>
                            <Td key="uploaded" fontSize="13px" title={dayjs(file.uploaded).format('lll')}>
                                {dayjs(file.uploaded).format('ll')}
                            </Td>
                            <Td textAlign="center">
                                <Menu size="sm" autoSelect={false} marginInlineStart="-14px" placement="bottom-end">
                                    <MenuButton
                                        size="xs"
                                        display="flex"
                                        as={IconButton}
                                        // color={menuButtonColor}
                                        icon={<AiOutlineMore size="20px" />}
                                        variant="ghost"
                                    />
                                    <MenuList minW={0}>
                                        <MenuItem onClick={() => fetch(file.downloadURL).then((response) => response.blob()).then((blob) => { fileDownload(blob, file.name) })}>
                                            Download
                                        </MenuItem>
                                        <MenuItem color="rarchy-red" onClick={async () => {

                                            const updateFirestore = async () => {

                                                /*** update pages data in redux ***/
                                                // var updatedPageData = cloneDeep(sitemap?.docs.pages);
                                                // updatedPageData[PageDrawer.page.id].files ? updatedPageData[PageDrawer.page.id].files-- : updatedPageData[PageDrawer.page.id].files = 0;
                                                var updatedPageData = cloneDeep(sitemap?.docs.pages[PageDrawer.page.id]);
                                                updatedPageData.files ? updatedPageData.files-- : updatedPageData.files = 0;
                                                // merge page edits
                                                dispatch(mergePagesEdits({ pages: updatedPageData }));
                                                /*** update pages data in redux ***/

                                                /*** update pages file count in firestore ***/
                                                var pages = { [PageDrawer.page.id]: { files: firestore.FieldValue.increment(-1) } };
                                                await firestore.doc(`sitemaps/${sitemap?.id}/data/pages`).set({ pages, lastEdit: user.id }, { merge: true }).catch(e => console.error(e));
                                                /*** update pages file count in firestore ***/

                                                /*** update files doc ***/
                                                const files = { [file.id]: firestore.FieldValue.delete() };
                                                await firestore.doc(`sitemaps/${sitemap?.id}/data/pages/files/${PageDrawer.page.id}`).set({ files, lastEdit: user.id }, { merge: true }).catch(e => console.error(e));
                                                /*** update files doc ***/
                                            }

                                            try {
                                                /*** update files state ***/
                                                setFiles(files.filter(f => f.key !== file.key))
                                                /*** update files state ***/
                                                // delete from firebase storage  
                                                await firebase.functions().httpsCallable('sitemaps-files-deleteFileFromStorage')({ sitemapId: sitemap?.id, page: PageDrawer.page.id, fileName: file.name });
                                                // update firestore
                                                updateFirestore();
                                                // push file deleted event to dataLayer
                                                window.dataLayer.push({ event: 'fileDeleted', fileSize: (file.size / Math.pow(1024, 2)).toFixed(2) }); // push file deleted event to dataLayer
                                            } catch (e) {
                                                console.error(e);
                                                if (e.code === 'not-found') { updateFirestore(); }
                                            }
                                        }}>
                                            Delete
                                        </MenuItem>
                                    </MenuList>
                                </Menu>
                            </Td>
                        </Tr>
                    )
                })}
            </Tbody>
        </Table>
    )
}